import React from "react";
import { useFormContext } from "react-hook-form";
import {
  UserLoginErrorCode,
  useSendMfaCodeMutation,
} from "../../generated/graphql";
import { Row } from "../Common/__styles__/Layout";
import { Button } from "../Common/Button";
import { Text } from "../Inputs/react-hook-form";
import { ErrorMessage } from "./ErrorMessage";
import { MFAFlowAction } from "common-client/utils/useMFAFlow";
import {
  ButtonRow,
  Title,
} from "../Common/__styles__/FormWithFullPageBackground";
import { PhoneNote } from "./__styles__/LoginPage";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { LoginFormStructure } from "./LoginPage";

export const ProvidePhoneNumber = ({
  dispatch,
  error,
}: {
  dispatch: React.Dispatch<MFAFlowAction>;
  error?: Maybe<{ message: string; code?: UserLoginErrorCode }>;
}) => {
  const { addFailureToast } = useStatusToasts();
  const { register, handleSubmit, watch } = useFormContext();

  const phoneNumber = watch("phoneNumber");
  const disabled = !phoneNumber;

  const [sendCode] = useSendMfaCodeMutation({
    onCompleted: data => {
      dispatch({ type: "sendCode", data: data.sendMFACode });
    },
    onError: () => {
      addFailureToast(
        `There was an issue sending the code. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const handleLogin = async (loginData: LoginFormStructure) => {
    await sendCode({
      variables: {
        data: {
          email: loginData.email,
          password: loginData.password,
          phoneNumber: loginData.phoneNumber!,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Title>Setup Multi-Factor Authentication</Title>
      {error && <ErrorMessage error={error} />}
      <div>
        <Row>
          <Text
            label="Mobile phone number"
            {...register("phoneNumber")}
            placeholder="(555) 555-5555"
            autoComplete="off"
          />
        </Row>
        <PhoneNote>
          Note: Enter a phone number where we can send an authentication code
          via SMS.
        </PhoneNote>
      </div>
      <ButtonRow>
        <Button
          type={"submit"}
          disabled={disabled}
          size="medium"
          styleVariant="primary"
        >
          Send code
        </Button>
      </ButtonRow>
    </form>
  );
};
