import React, { useContext } from "react";
import { validate } from "jsonschema";
import {
  RegistryWidgetsType,
  RJSFValidationError,
  RegistryFieldsType,
} from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import Form, { IChangeEvent } from "@rjsf/core";

import {
  CustomCheckbox,
  CustomDatePicker,
  CustomSelect,
  CustomText,
  CustomTextarea,
  DamageDegree,
} from "./Widgets";
import {
  DocumentUploadInput,
  NumberInput,
  TagsInput,
  NumberWithUnitsInput,
  PropertyMarketValueInput,
} from "./Fields";

import { form } from "./__styles__/Form";
import {
  CertificateUpload,
  DocumentUpload,
  Submission,
  SubmissionType,
  SubmissionTypeVersion,
} from "../../generated/graphql";
import { DocumentUpload as DocumentUploadFieldType } from "./Fields/DocumentUploadField";
import { capitalize, flatMap, isArray, merge } from "lodash";
import { SubmissionDefaultValuesContext } from "common/services/formBuilderService";
import { formatDateString } from "common/utils/dates";
import {
  DescriptionFieldTemplate,
  ObjectFieldTemplate,
  TitleFieldTemplate,
} from "./Templates";
import { MODULE_CONFIGURATIONS } from "./modules";
import Disclaimer from "../Common/Disclaimer";
import { AuthContext } from "../Authorization/AuthContext";

const transformErrors = (errors: Array<RJSFValidationError>) => {
  return errors.map(error => {
    error.message = capitalize(error.message);

    if (error.name === "required") {
      error.message = "This field is required";
      error.property = `.${error.params.missingProperty}`;
    }
    return error;
  });
};

export type FormDataType = unknown;

export interface SubmissionFormContextType {
  documentUploads: Maybe<
    Array<
      Pick<DocumentUpload, "id" | "originalFilename"> & {
        certificateUpload?: Maybe<
          Pick<CertificateUpload, "status" | "cancelationReason">
        >;
      }
    >
  >;
  defaultValues: SubmissionDefaultValuesContext;
  editing: boolean;
}

export const SubmissionFormContext =
  React.createContext<SubmissionFormContextType>({
    documentUploads: null,
    defaultValues: {} as SubmissionDefaultValuesContext,
    editing: false,
  });

type RelatedSubmission = Pick<Submission, "id"> & {
  submissionTypeVersion: {
    submissionType: Pick<SubmissionType, "modules" | "name">;
  };
};

export interface FormComponentProps {
  submitFormRef?: React.RefObject<HTMLButtonElement>;
  submissionType: {
    modules: SubmissionType["modules"];
    currentVersion: Pick<SubmissionTypeVersion, "formStructure">;
  };
  existingSubmission?: Maybe<
    Pick<Submission, "formData"> & {
      relatedSubmissions: Array<RelatedSubmission>;
      documentUploads: Array<Pick<DocumentUpload, "id" | "originalFilename">>;
    }
  >;
  disabled?: boolean;
  onSubmit: (formData: FormDataType) => void;
  onChange?: (data: { isValid: boolean; isDirty: boolean }) => void;
  onError?: (errors: RJSFValidationError[]) => void;
  defaultValues?: Omit<
    SubmissionDefaultValuesContext,
    "units" | "boolean" | "date"
  >;
  initialFormData?: FormDataType;
}

const isDocumentUploadField = (field: any) => {
  return isArray(field) && field.length && !!field[0].accountDocumentTypeId;
};

const widgets: RegistryWidgetsType = {
  SelectWidget: CustomSelect,
  TextareaWidget: CustomTextarea,
  DateWidget: CustomDatePicker,
  DamageDegree: DamageDegree,
  TextWidget: CustomText,
  CheckboxWidget: CustomCheckbox,
};

const fields: RegistryFieldsType = {
  DocumentUploader: DocumentUploadInput,
  Tags: TagsInput,
  NumberField: NumberInput,
  NumberWithUnits: NumberWithUnitsInput,
  PropertyMarketValue: PropertyMarketValueInput,
};

export const FormComponent = ({
  submitFormRef,
  submissionType,
  existingSubmission,
  initialFormData,
  disabled = false,
  onSubmit,
  onChange = () => {},
  onError = (_: RJSFValidationError[]) => {},
  defaultValues,
}: FormComponentProps) => {
  const [formState, setFormState] = React.useState<FormDataType>(
    initialFormData ?? existingSubmission?.formData ?? {}
  );

  const { admin, account } = useContext(AuthContext);

  const { modules, currentVersion } = submissionType;
  const { schema, uiSchema } = currentVersion.formStructure;

  const handleChange = ({ formData: rawFormData }: IChangeEvent) => {
    const formData = modules.reduce(
      (formData, module) => {
        return MODULE_CONFIGURATIONS[module].onFormDataChange(formData);
      },
      { ...rawFormData }
    );
    setFormState(formData);

    let allDocumentFieldsAreValid = true;
    // Need to use traditional for-of in order to break
    for (const key of Object.keys(formData)) {
      if (isDocumentUploadField(formData[key])) {
        const valid = formData[key].every(
          (item: DocumentUploadFieldType) =>
            !item.status || item.status === "valid"
        );

        if (!valid) {
          allDocumentFieldsAreValid = false;
          break;
        }
      }
    }

    const isValid =
      validate(formData, schema).valid && allDocumentFieldsAreValid;

    onChange({ isValid, isDirty: true });
  };

  const handleSubmit = ({ formData }: IChangeEvent) => {
    setFormState(formData);

    onSubmit(formData);
  };

  const parcel = defaultValues?.parcel;
  const user = defaultValues?.user;

  const disclaimers = existingSubmission
    ? flatMap(modules, module => {
        return MODULE_CONFIGURATIONS[module].buildDisclaimers({
          submission: existingSubmission,
          user: { isAdmin: !!admin, accountId: account!.id },
        });
      })
    : [];

  return (
    <SubmissionFormContext.Provider
      value={{
        editing: !!existingSubmission,
        documentUploads: existingSubmission?.documentUploads ?? null,
        defaultValues: merge(
          {
            units: {
              feet: "feet",
              inches: "inches",
              hours: "hours",
              minutes: "minutes",
              days: "days",
            } as const,
            boolean: { true: true, false: false } as const,
            parcel: {
              mailingAddress:
                parcel?.mailingAddress1 && parcel.mailingAddress2
                  ? `${parcel.mailingAddress1} ${parcel.mailingAddress2}`
                  : undefined,
            },
            user: {
              fullName:
                user?.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : undefined,
            },
            date: {
              today: formatDateString({
                dateString: new Date().toDateString(),
                format: "YYYY-MM-DD",
              }),
            },
          },
          defaultValues
        ),
      }}
    >
      {disclaimers.map((disclaimer, index) => (
        <Disclaimer key={index} message={disclaimer} />
      ))}
      <Form
        disabled={disabled}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        widgets={widgets}
        fields={fields}
        onSubmit={handleSubmit}
        className={form()}
        formData={formState}
        noHtml5Validate={false}
        transformErrors={transformErrors}
        showErrorList={false}
        onError={onError}
        onChange={handleChange}
        templates={{
          TitleFieldTemplate,
          DescriptionFieldTemplate,
          ObjectFieldTemplate,
        }}
      >
        {submitFormRef && (
          <button
            ref={submitFormRef}
            type="submit"
            style={{ display: "none" }}
          />
        )}
      </Form>
    </SubmissionFormContext.Provider>
  );
};
