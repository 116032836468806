import React from "react";
import { BottomBannerWrapper } from "../Maps/__styles__/FullPageMap";
import {
  Container,
  Header,
  InfoContainer,
  LeftSide,
  Title as StyledTitle,
} from "./__styles__/BottomBanner";
import { usedActivationKey } from "../Maps/MeasureTool/utils";
import { Icon } from "./Icons/LucideIcons";
import { Body } from "./Typography";

type BottomBannerStyles = "default" | "alert" | "warning";

const styleConstants = {
  alert: {
    color: "contentCriticalDark",
    iconName: "alert-circle",
  },
  warning: { color: "contentAttention", iconName: "triangle-alert" },
  default: { color: "contentPrimaryDark", iconName: null },
} as const;

export const BottomBanner = ({
  title,
  subtitle,
  children,
  onClose,
  style = "default",
  panelOpen = false,
  closeTabIndex = -1,
  tooltipComponent,
}: {
  title: React.ReactNode | string;
  subtitle?: string;
  children: React.ReactNode;
  onClose: () => void;
  style?: BottomBannerStyles;
  panelOpen?: boolean;
  closeTabIndex?: number;
  tooltipComponent?: React.ReactNode;
}) => {
  const { color, iconName } = styleConstants[style];

  return (
    <BottomBannerWrapper style={{ ...(panelOpen && { left: "50%" }) }}>
      {tooltipComponent}
      <Container>
        <LeftSide>
          <Header>
            {typeof title === "string" ? (
              <StyledTitle>
                {iconName && (
                  <Icon iconName={iconName} color={color} size="16" />
                )}
                <Body size="default" type="emphasis" color={color}>
                  {title}
                </Body>
              </StyledTitle>
            ) : (
              title
            )}
          </Header>
          <InfoContainer>
            {subtitle && (
              <Body type="regular" size="small" color="contentSecondaryDark">
                {subtitle}
              </Body>
            )}
            {children}
          </InfoContainer>
        </LeftSide>
        <Icon
          iconName="x"
          color="contentPrimaryDark"
          onClick={onClose}
          onKeyDown={e => {
            if (usedActivationKey(e)) {
              e.preventDefault();
              onClose();
            }
          }}
          size="16"
          cursor="pointer"
          tabIndex={closeTabIndex}
        />
      </Container>
    </BottomBannerWrapper>
  );
};
