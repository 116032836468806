import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useModal } from "react-modal-hook";
import { gql } from "@apollo/client";
import Modal from "../Common/Modal";
import { Button } from "../Common/Button";
import "./__styles__/IssueModal.scss";
import { useCreateIssueMutation } from "../../generated/graphql";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../Common/__styles__/Modal";
import { useForm } from "react-hook-form";
import { Textarea } from "../Inputs/react-hook-form";

export const CREATE_ISSUE = gql`
  mutation CreateIssue($data: IssueInput!) {
    createIssue(data: $data) {
      id
    }
  }
`;

export type IssueModalProps = {
  address?: Maybe<string>;
  certificateId?: string;
  placeholder?: string;
  onCancel: () => void;
  onSave: () => void;
  coordinates: readonly [number, number];
};

type IssueModalHookProps = Pick<
  IssueModalProps,
  "address" | "coordinates" | "certificateId"
>;

type IssueModalShowProps = Partial<Pick<IssueModalProps, "placeholder">>;

export const useIssueModal = ({ ...props }: IssueModalHookProps) => {
  const [showProps, setShowProps] = useState<IssueModalShowProps>({});

  const [show, hide] = useModal(() => {
    return (
      <Modal onRequestClose={hide}>
        <IssueModal onCancel={hide} onSave={hide} {...props} {...showProps} />
      </Modal>
    );
  }, [props]);

  const showWithProps = (additionalProps: IssueModalShowProps = {}) => {
    setShowProps(additionalProps);
    show();
  };

  return [showWithProps, hide] as const;
};

type IssueFormStructure = {
  content: string;
};

const IssueModal = ({
  address,
  coordinates,
  certificateId,
  placeholder = "",
  onCancel,
  onSave,
}: IssueModalProps) => {
  const { addToast } = useToasts();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<IssueFormStructure>({ defaultValues: { content: placeholder } });

  const [createIssue, { loading }] = useCreateIssueMutation({
    onCompleted: () => {
      onSave();
      addToast(
        "Thank you for your feedback! A support team member will be in touch shortly to follow up.",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    },
    onError: () => {
      addToast(
        "There was an issue saving your report. Please try again. If the problem persists, please email us at support@withforerunner.com",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
  });

  const handleCancel = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    onCancel();
  };

  const onSubmit = async (formData: IssueFormStructure) => {
    await createIssue({
      variables: {
        data: {
          content: formData.content,
          address,
          coordinates: {
            type: "Point",
            coordinates,
          },
          certificateId,
        },
      },
    });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Report data issue</h1>
        <h2>Let us know what's wrong so that we can work to resolve it.</h2>
      </HeaderSection>
      <FormSection>
        <ContentSection data-testid="inputs">
          <Textarea
            {...register("content", {
              required: "Please enter text to proceed",
            })}
            name="content"
            placeholder="Write about your issue here..."
            disabled={loading}
            error={errors.content?.message}
            required
          />
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              onClick={handleCancel}
              disabled={loading}
              size="medium"
            >
              Cancel
            </Button>
            <Button
              styleVariant="primary"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              size="medium"
            >
              Save
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default IssueModal;
