import React, { ComponentProps, useMemo } from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

import "./__styles__/Tooltip.scss";
import { Icon } from "./Icons/LucideIcons";
import { colors } from "../../stitches.config";

const Tooltip = ({
  content,
  popoverContent,
  id,
  ...tooltipProps
}: {
  content: string | JSX.Element;
  popoverContent: JSX.Element;
  id?: string;
} & TooltipProps) => {
  const autoId = useMemo(() => uuidv4(), []);
  const finalId = id ?? autoId;

  return (
    <span tabIndex={0} data-tip data-for={finalId}>
      {content}
      <ReactTooltip
        styleName="tooltip"
        backgroundColor={colors.bgUiSurface.toString()}
        id={finalId}
        {...tooltipProps}
      >
        {popoverContent}
      </ReactTooltip>
    </span>
  );
};

export default Tooltip;

export const InformationalTooltip: React.FC<
  Omit<ComponentProps<typeof Tooltip>, "content"> & {
    iconProps?: Partial<ComponentProps<typeof Icon>>;
  }
> = ({ iconProps, ...props }) => {
  const content = (
    <Icon
      iconName="info"
      color="contentSecondary"
      size={16}
      cursor="pointer"
      {...iconProps}
    />
  );

  return <Tooltip {...props} content={content} />;
};
