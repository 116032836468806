import { countBy, isEqual } from "lodash";
import validator from "@rjsf/validator-ajv8";
import Form, { IChangeEvent } from "@rjsf/core";
import { track } from "../../../../../utils/tracking";
import React, { createRef, useEffect, useState } from "react";
import { RegistryWidgetsType } from "@rjsf/utils";
import {
  GetDocumentUploadQuery,
  useUpdateGeneratedDocumentMutation,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { HEADER_OFFSET, fieldWrapperId } from "../../../Forms/utils";

import { Button } from "../../../../Common/Button";
import MobileDocumentLink from "../../../MobileDocumentLink";
import Icon, { ICON_COLORS, Icons } from "../../../../Common/Icons";

import {
  FormButtons,
  Loading,
  ProcessingWrapper,
} from "../../../Forms/__styles__/Form";
import { Title, form } from "./__styles__/FormFields";
import { BooleanSelect, TextField } from "./CustomFields";

export interface FormFieldsProps {
  documentUpload: NonNullable<GetDocumentUploadQuery["documentUpload"]>;
  fileUrl: string;
  sidebarRef: React.RefObject<HTMLDivElement>;
  onUpdate: () => void;
}

export type FormFieldsContextType = {
  fieldsOpen: Record<string, boolean>;
  setFieldsOpen: (fieldsOpen: Record<string, boolean>) => void;
};

export const FormFieldsContext = React.createContext<FormFieldsContextType>(
  {} as any
);

export default ({
  documentUpload,
  fileUrl,
  sidebarRef,
  onUpdate,
}: FormFieldsProps) => {
  const {
    id: documentUploadId,
    formData: currentFormData,
    formStructure,
  } = documentUpload;

  const [fieldsOpen, setFieldsOpen] = useState<Record<string, boolean>>({});
  const [updatedFields, setUpdatedFields] = useState<Array<string>>([]);
  const { addFailureToast, addSuccessToast } = useStatusToasts();
  const [formData, setFormData] = useState(currentFormData);
  const submitFormRef = createRef<HTMLButtonElement>();

  const [updateGeneratedDocument, { loading }] =
    useUpdateGeneratedDocumentMutation({
      onCompleted: () => {
        track("Generated document fields updated", {
          documentType: documentUpload.accountDocumentType.name,
          propertyAddress: documentUpload.property?.fullAddress,
          fieldsUpdated: updatedFields.toString(),
        });
        setUpdatedFields([]);
        addSuccessToast(
          "Your edits were successfully saved and your document was reprocessed"
        );
        onUpdate();
      },
      onError: error => {
        const message = error.message.includes(
          "The document you are attempting to update has been deleted."
        )
          ? error.message
          : "There was an error saving your edits. Please try again. If the problem persists, please email us at support@withforerunner.com";
        addFailureToast(message);
      },
      variables: {
        documentUploadId,
        formData,
      },
    });

  const handleChange = ({ formData }: IChangeEvent) => {
    let newUpdatedFields = [];

    for (const key in formData) {
      if (formData[key] !== currentFormData[key]) {
        newUpdatedFields.push(key);
      }
    }

    setUpdatedFields(newUpdatedFields);
    setFormData(formData);
  };

  const handleSubmit = async () => {
    setFieldsOpen({});
    await updateGeneratedDocument();
  };

  const widgets: RegistryWidgetsType = {
    TextWidget: TextField,
    SelectWidget: BooleanSelect,
  };

  const expandedFieldCount = countBy(Object.values(fieldsOpen))["true"] ?? 0;

  const expandedField = window.location.hash.replace("#", "");

  useEffect(() => {
    const field = document.getElementById(fieldWrapperId(expandedField));
    const fieldOffset = field?.offsetTop ?? 0;

    if (sidebarRef.current) {
      sidebarRef.current.scrollTo({
        top: fieldOffset - HEADER_OFFSET,
      });
    }
  }, [expandedField]);

  return (
    <FormFieldsContext.Provider value={{ fieldsOpen, setFieldsOpen }}>
      <Title>Form fields</Title>
      <Form
        className={form()}
        schema={formStructure.schema}
        uiSchema={formStructure.uiSchema}
        validator={validator}
        formData={formData}
        widgets={widgets}
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        {
          <button
            type="submit"
            ref={submitFormRef}
            style={{ display: "none" }}
          />
        }
        {(loading || expandedFieldCount > 0) && (
          <FormButtons>
            {loading && (
              <ProcessingWrapper>
                Saving document{" "}
                <Loading>
                  <Icon
                    icon={Icons.SMALL_LOADING}
                    color={ICON_COLORS.LIGHT_GREY}
                  />
                </Loading>
              </ProcessingWrapper>
            )}

            <Button
              styleVariant="secondary"
              disabled={loading}
              onClick={() => setFieldsOpen({})}
              size="medium"
            >
              Cancel
            </Button>
            <Button
              disabled={loading || isEqual(currentFormData, formData)}
              onClick={handleSubmit}
              styleVariant="primary"
              size="medium"
            >
              Save {updatedFields.length > 0 ? `(${updatedFields.length})` : ""}
            </Button>
          </FormButtons>
        )}
      </Form>
      <MobileDocumentLink fileUrl={fileUrl} documentUpload={documentUpload} />
    </FormFieldsContext.Provider>
  );
};
