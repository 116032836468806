import React, { useCallback, useContext, useEffect, useRef } from "react";
import { IconNames } from "../../Common/Icons/LucideIcons";
import { capitalize } from "lodash";
import Loading from "../../Common/Icons/SmallLoading";
import { Button } from "../../Common/Button";
import { LayerContext, Raster } from "../layers";
import { useGetBandValueLazyQuery } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { AuthContext } from "../../Authorization/AuthContext";
import { BottomBanner } from "../../Common/BottomBanner";
import {
  A11yTooltip,
  ActionButtons,
  Calculation,
} from "../../Common/__styles__/BottomBanner";
import { Body } from "../../Common/Typography";
import { usedEscapeKey } from "./utils";
import { GetHelpLink } from "../../Guest/GetHelpLink";

export const RasterMeasure = ({
  title,
  subtitle,
  parentLoading = false,
  actionName,
  primaryButtonIconName,
  secondaryButton,
  onActionClick,
  raster,
}: {
  title: React.ReactNode | string;
  subtitle: string;
  parentLoading?: boolean;
  actionName: string;
  primaryButtonIconName?: IconNames;
  secondaryButton?: React.ReactNode;
  onActionClick: (value: string) => void;
  raster: Raster;
}) => {
  const { addFailureToast } = useStatusToasts();
  const {
    measureToolDispatch,
    measureToolState,
    toggleLayer,
    visibleRaster: getVisibleRaster,
  } = useContext(LayerContext);

  const [getBandValue, { loading: getBandValueLoading, data }] =
    useGetBandValueLazyQuery({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        measureToolDispatch({
          type: "setLoading",
          data: { loading: false },
        });
      },
      onError: () => {
        measureToolDispatch({
          type: "setLoading",
          data: { loading: false },
        });
        addFailureToast(
          "There was an error obtaining a value. Please try again."
        );
      },
    });

  useEffect(() => {
    if (
      !getBandValueLoading &&
      !parentLoading &&
      measureToolState.measureToolMode === "raster" &&
      measureToolState.coordinates.length
    ) {
      measureToolDispatch({
        type: "setLoading",
        data: { loading: true },
      });
      void getBandValue({
        variables: {
          ...measureToolState.coordinates[0]!,
          rasterId: raster.id,
        },
      });
    }
  }, [measureToolState.coordinates]);

  useEffect(() => {
    if (
      measureToolState.coordinates.length &&
      measureToolState.dataMode === "observe"
    ) {
      measureToolDispatch({
        type: "setLoading",
        data: { loading: true },
      });
      void getBandValue({
        variables: {
          ...measureToolState.coordinates[0]!,
          rasterId: raster.id,
        },
      });
    }
  }, [raster.id]);

  const bandValue = data?.getBandValue;
  const hasMeasurement = !!bandValue;
  let measurementDisplay: React.ReactNode;
  let measurementValue = "";
  if (bandValue === undefined) {
    measurementDisplay = <div data-testid="undefined-display">-</div>;
  } else if (bandValue === null) {
    measurementDisplay = (
      <div style={{ fontStyle: "italic" }}>
        No data available at this location
      </div>
    );
  } else {
    measurementValue = bandValue.toFixed(3);
    measurementDisplay = (
      <div>
        {measurementValue} {raster.bandMappings.measurementUnit}
      </div>
    );
  }

  const primaryButton = (
    <Button
      styleVariant="outlineDark"
      size="small"
      onClick={() => onActionClick(measurementValue)}
      leftIconName={primaryButtonIconName}
      loading={parentLoading}
      tabIndex={-1}
    >
      {actionName} value
    </Button>
  );

  const rasterIsVisible = getVisibleRaster()?.id === raster.id;
  const layerName = rasterIsVisible ? "base" : "raster";

  const onToggleBaseLayer = () => {
    toggleLayer({
      group: "rasters",
      id: raster.id,
      isVisible: !rasterIsVisible,
    });
  };

  const onClose = () => {
    if (parentLoading) {
      return;
    }
    measureToolDispatch({
      type: "setMeasureMode",
      data: { measureToolMode: "off" },
    });

    if (rasterIsVisible) {
      toggleLayer({
        group: "rasters",
        id: raster.id,
        isVisible: false,
      });
    }
  };

  return (
    <MeasureInfo title={title} subtitle={subtitle} onClose={onClose}>
      <Body as="div" type="emphasis" size="default" color="contentPrimaryDark">
        <Calculation>
          {capitalize(raster.bandMappings.measurementName)} value:
          {getBandValueLoading ? (
            <Loading data-testid="loading" />
          ) : (
            measurementDisplay
          )}
        </Calculation>
      </Body>
      <ActionButtons>
        <Button
          styleVariant="outlineDark"
          size="small"
          onClick={onToggleBaseLayer}
          tabIndex={-1}
        >
          Show {layerName} layer
        </Button>
        {hasMeasurement && !getBandValueLoading && primaryButton}
        {secondaryButton}
      </ActionButtons>
    </MeasureInfo>
  );
};

export const MeasureInfo = ({
  title,
  subtitle,
  children,
  onClose,
}: {
  title: React.ReactNode;
  subtitle: string;
  children: React.ReactNode;
  onClose: () => void;
}) => {
  const { isGuest } = useContext(AuthContext);
  const { a11yEnabled } = useContext(LayerContext);
  const a11yTooltipRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // casting e to React.KeyboardEvent to avoid type errors
      if (usedEscapeKey({ key: e.key } as React.KeyboardEvent<HTMLElement>)) {
        e.preventDefault();
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (a11yEnabled && isGuest) {
      a11yTooltipRef.current?.focus();
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [a11yEnabled, isGuest]);

  const tooltipComponent = a11yEnabled && isGuest && (
    <A11yTooltip ref={a11yTooltipRef} tabIndex={0}>
      <Body type="regular" size="default" color="contentPrimaryDark">
        If relying on keyboard navigation, please <GetHelpLink /> to obtain
        values
      </Body>
    </A11yTooltip>
  );

  return (
    <BottomBanner
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      closeTabIndex={a11yEnabled && isGuest ? 0 : -1}
      tooltipComponent={tooltipComponent}
    >
      {children}
    </BottomBanner>
  );
};
