import React, { useContext } from "react";
import { FIRMInfoRow } from "../FIRMInfo/FIRMTable/Rows/FIRMInfoRow";
import { getCustomMapRowContent } from "common-client/utils/firms";
import { PropertyAttributeLinkContainer } from "../FIRMInfo/FIRMTable/__styles__/FIRMTable";
import { Icon } from "../../../Common/Icons/LucideIcons";
import { RenderMode } from "../FIRMInfo/types";
import { PropertyOverviewContext } from "../PropertyOverviewContext";
import { PropertyOverviewTooltip } from "./PropertyOverviewTooltip";
import { AccountPropertyAttribute } from "../../../../generated/graphql";

export const CustomMapRow = ({
  accountPropertyAttribute,
  size,
}: {
  accountPropertyAttribute: Pick<
    AccountPropertyAttribute,
    | "sourceId"
    | "displayValueAsLink"
    | "label"
    | "isPublic"
    | "tooltip"
    | "displayConfig"
  >;
  size: RenderMode;
}) => {
  const {
    sourceId,
    displayValueAsLink,
    label,
    isPublic,
    tooltip,
    displayConfig,
  } = accountPropertyAttribute;

  const tooltipElement = PropertyOverviewTooltip({
    tooltipMarkdown: tooltip,
  });
  const { customMapsInfo } = useContext(PropertyOverviewContext);

  const customMap = customMapsInfo.find(map => map.id === sourceId);

  if (!customMap) {
    return null;
  }

  const { value, defaultDisplay } = getCustomMapRowContent({
    map: customMap,
    displayConfig,
  });

  if (!value && !defaultDisplay) {
    return null;
  }

  const wrapper =
    displayValueAsLink && value
      ? () => {
          return (
            <PropertyAttributeLinkContainer>
              <span>
                <Icon iconName="link" color="contentInfo" size={16} />
              </span>
              <a href={value} target="_blank">
                Link
              </a>
            </PropertyAttributeLinkContainer>
          );
        }
      : () => {
          return <>{value || defaultDisplay}</>;
        };

  return (
    <FIRMInfoRow
      label={label}
      property={customMap.name}
      wrap={wrapper}
      isPublic={isPublic}
      tooltip={tooltipElement}
      size={size}
    />
  );
};
