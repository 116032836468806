import React, { ComponentProps } from "react";
import { capitalize } from "lodash";

import ListItem, { ListItemProps } from "./ListItem";
import { Improvement } from "../../../generated/graphql";
import { formatCurrency } from "common/utils/strings";

export type PartialImprovement = Pick<
  Improvement,
  | "value"
  | "exclusion"
  | "id"
  | "date"
  | "kind"
  | "description"
  | "progress"
  | "__typename"
>;

export const isImprovement = (
  item: ListItemProps["item"]
): item is PartialImprovement => {
  return (item as PartialImprovement).__typename === "Improvement";
};

type ImprovementListItemProps = Pick<
  ComponentProps<typeof ListItem>,
  "address" | "onUpdate"
> & { item: PartialImprovement };

const ImprovementListItem = ({
  address,
  onUpdate,
  item,
}: ImprovementListItemProps) => {
  const title = capitalize(item.kind);
  const info = `${(item.progress ? item.progress * 100 : 0 * 100).toFixed(
    2
  )}% (${formatCurrency(item.value)})`;

  return (
    <ListItem
      address={address}
      onUpdate={onUpdate}
      item={item}
      key={item.id}
      date={item.date}
      title={title}
      info={info}
      excluded={item.exclusion!.excluded}
    />
  );
};

export default ImprovementListItem;
