import React, { ReactNode } from "react";
import { isNil } from "lodash";

import {
  AccountDocumentType,
  ActivityTargetType,
  ActivityType,
  GetActivitiesQuery,
  UpdatedFirmFieldsType,
} from "../../../generated/graphql";
import {
  ADDRESS_PANEL_TAB_NAME,
  buildLink,
  pipLink,
  RECORDS_TAB_NAME,
} from "common/routing";
import { useLocation } from "react-router-dom";
import { getCurrentURL } from "../../../utils/url";
import { track } from "../../../utils/tracking";
import { AuthContext } from "../../Authorization/AuthContext";
import {
  buildGuestDocumentUploadDetailURL,
  buildResidentPropertyURL,
} from "common/utils/url";
import { documentLinkText } from "../../../utils/documentUploads";
import { certificateLinkText } from "../../../utils/certificates";

import { StyledAnchor, StyledLink } from "./__styles__/Message";
import { formatCoordinates } from "common/utils/coordinates";
import { ACTIVITY_TARGET_TYPE } from "common/constants";

export type FeedSource = "Account" | "Property";

type Activity = GetActivitiesQuery["activities"][number];
type Target = Activity["target"];

interface CertificateTarget {
  issuedAt?: string;
  documentUpload: {
    id: string;
  };
}

interface DocumentUploadTarget {
  issuedAt?: string;
  accountDocumentType: AccountDocumentType;
}

interface SubmissionTarget {
  id: string;
  submissionTypeVersion: {
    id: string;
    submissionType: { id: string; name: string };
  };
}

interface PropertyWarningTarget {
  title: string;
}

const isCertificateUploadTarget = (
  target: Target
): target is CertificateTarget => {
  return target?.__typename == "Certificate";
};

const isDocumentUploadTarget = (
  target: Target
): target is DocumentUploadTarget => {
  return target?.__typename == "DocumentUpload";
};

const isSubmissionTarget = (target: Target): target is SubmissionTarget => {
  return target?.__typename == "Submission";
};

const isPropertyWarningTarget = (
  target: Target
): target is PropertyWarningTarget => {
  return target?.__typename == "PropertyWarning";
};

const isTargetDeleted = (activity: Activity) => {
  const targetIsDeletable = [
    ActivityTargetType.CERTIFICATE,
    ActivityTargetType.DOCUMENT_UPLOAD,
    ActivityTargetType.LOG,
    ActivityTargetType.SUBMISSION,
  ].includes(activity.targetType);

  return targetIsDeletable && isNil(activity.target);
};

export interface MessageProps {
  activity: Activity;
  latitude?: Maybe<number>;
  longitude?: Maybe<number>;
  propertyId?: Maybe<string>;
  fullAddress?: Maybe<string>;
  linkToProperty?: Maybe<boolean>;
  feedSource: FeedSource;
}

interface MessagePartBuilderArgs {
  activity: MessageProps["activity"];
  targetId?: string;
  lat: Maybe<string>;
  lng: Maybe<string>;
  propertyId?: Maybe<string>;
  address?: Maybe<string>;
  accountId: string;
  subdomain: string;
  feedSource: FeedSource;
}

type MessagePartBuilder = (props: MessagePartBuilderArgs) => {
  action?: string;
  urlToTarget: any;
  targetDescription: string;
  indefiniteArticle: string;
};

const MessagePartBuilders: Record<ActivityTargetType, MessagePartBuilder> = {
  [ActivityTargetType.CERTIFICATE]: ({ activity }) => {
    let action: string;
    switch (activity.type) {
      case ActivityType.UPDATED:
        action = "updated extracted fields from";
        break;
      case ActivityType.CREATED:
        action = "finished processing";
        break;
      default:
        action = activity.type;
    }

    const id = activity.targetInfo.id ?? undefined;

    let targetDescription: string, pathname: string;
    if (isCertificateUploadTarget(activity.target)) {
      targetDescription = certificateLinkText(activity.target.issuedAt ?? null);
      pathname = buildLink("documentUploadDetail", {
        id: activity.target.documentUpload.id,
      });
    } else {
      targetDescription = "a certificate";
      pathname = buildLink("elevationCertificateDetail", { id });
    }

    return {
      indefiniteArticle: "",
      urlToTarget: {
        pathname,
      },
      targetDescription,
      action,
    };
  },
  [ActivityTargetType.DOCUMENT_UPLOAD]: ({ activity, targetId, subdomain }) => {
    let action: string;
    let indefiniteArticle: string;

    switch (activity.type) {
      case ActivityType.CREATED:
        action = "generated";
        indefiniteArticle = "a";
        break;
      case ActivityType.UPDATED:
        action = "updated fields";
        indefiniteArticle = "from";
        break;
      case ActivityType.SHARED:
        action = "shared";
        indefiniteArticle = "";
        break;
      default:
        action = activity.type;
        indefiniteArticle = "a";
    }

    let targetDescription: string, urlToTarget: { pathname: string };
    targetDescription = isDocumentUploadTarget(activity.target)
      ? documentLinkText({
          issuedAt: activity.target.issuedAt ?? null,
          accountDocumentType: activity.target.accountDocumentType,
        })
      : "document";
    if (activity.type === ActivityType.SHARED) {
      urlToTarget = {
        pathname: buildGuestDocumentUploadDetailURL({
          subdomain,
          documentUploadId: targetId!,
          protocol: window.location.protocol,
          appDomain: window.env.APPLICATION_DOMAIN,
        }),
      };
    } else {
      urlToTarget = {
        pathname: buildLink("documentUploadDetail", { id: targetId }),
      };
    }

    return {
      action,
      urlToTarget,
      targetDescription,
      indefiniteArticle,
    };
  },
  [ActivityTargetType.LOG]: ({ targetId, activity }) => {
    const urlToTarget =
      activity.type === ActivityType.DELETED
        ? null
        : {
            pathname: buildLink("editLog", { id: targetId }),
          };
    return {
      indefiniteArticle: "a",
      urlToTarget,
      targetDescription: "log",
    };
  },
  [ActivityTargetType.FIRM]: ({ activity, propertyId, lat, lng, address }) => {
    let indefiniteArticle = "a";
    let targetDescription: string;
    if (activity.targetInfo.attributeLabel) {
      return {
        action: "updated",
        indefiniteArticle: "the",
        targetDescription: activity.targetInfo.attributeLabel,
        urlToTarget: null,
      };
    }

    const updatedFIRMFields = activity.targetInfo.updatedFIRMFields;
    if (updatedFIRMFields === UpdatedFirmFieldsType.FLOODZONE_AND_BFE) {
      targetDescription = "Flood zone & BFE";
    } else if (updatedFIRMFields === UpdatedFirmFieldsType.DATUM) {
      targetDescription = "datum";
      indefiniteArticle = "the";
    } else {
      targetDescription = "FIRM panel & Effective Date";
    }

    const urlToTarget = pipLink({
      address,
      propertyId,
      lat,
      lng,
      tab: ADDRESS_PANEL_TAB_NAME.OVERVIEW,
    });

    return {
      urlToTarget,
      indefiniteArticle,
      targetDescription,
    };
  },
  [ActivityTargetType.COMMENT]: () => {
    return {
      urlToTarget: null,
      indefiniteArticle: "a",
      targetDescription: "comment",
    };
  },
  [ActivityTargetType.DESIGN_FLOOD_ELEVATION]: () => {
    return {
      urlToTarget: null,
      indefiniteArticle: "the",
      targetDescription: "design flood elevation",
    };
  },
  [ActivityTargetType.PROPERTY]: ({
    subdomain,
    propertyId,
    activity,
    feedSource,
  }) => {
    const propertyTypeOptions: Partial<
      Record<
        ActivityType,
        () => {
          action?: string;
          indefiniteArticle?: string;
          targetDescription: string;
          urlToTarget?: { pathname: string };
        }
      >
    > = {
      [ActivityType.SHARED]: () => {
        return {
          targetDescription: "public property link",
          urlToTarget: {
            pathname: buildResidentPropertyURL({
              subdomain,
              propertyId: propertyId!,
              protocol: window.location.protocol,
              appDomain: window.env.APPLICATION_DOMAIN,
            }),
          },
        };
      },
      [ActivityType.CREATED]: () => {
        return {
          action: "added",
          indefiniteArticle: feedSource === "Account" ? "a" : "this",
          targetDescription:
            feedSource === "Account" ? "new property" : "property",
        };
      },
      [ActivityType.DELETED]: () => {
        return {
          targetDescription: "property",
        };
      },
      [ActivityType.UPDATED]: () => {
        return {
          action: "edited",
          indefiniteArticle: "",
          targetDescription: "property details",
        };
      },
      [ActivityType.HID]: () => {
        return {
          action: "hid",
          indefiniteArticle: "",
          targetDescription: "property from the public",
        };
      },
      [ActivityType.DISPLAYED]: () => {
        return {
          action: "displayed",
          indefiniteArticle: "",
          targetDescription: "property to the public",
        };
      },
    };

    const typeOption = propertyTypeOptions[activity.type]?.();

    return {
      action: typeOption?.action ?? activity.type,
      indefiniteArticle: typeOption?.indefiniteArticle ?? "a",
      targetDescription: typeOption?.targetDescription ?? "",
      urlToTarget: typeOption?.urlToTarget ?? null,
    };
  },
  [ActivityTargetType.BATCH_CERTIFICATE_UPLOAD]: ({ activity }) => {
    const batchCertificateUploadCount =
      activity.targetInfo.batchCertificateUploadCount;
    return {
      urlToTarget: null,
      indefiniteArticle: `${batchCertificateUploadCount}`,
      targetDescription: `Elevation Certificate${
        batchCertificateUploadCount! > 1 ? "s" : ""
      }`,
    };
  },
  [ActivityTargetType.PROPERTY_WARNING]: ({
    activity,
    address,
    propertyId,
    lat,
    lng,
  }) => {
    const urlToTarget = pipLink({
      address,
      propertyId,
      lat,
      lng,
    });
    const warningTitle = isPropertyWarningTarget(activity.target)
      ? `"${activity.target.title}"`
      : "";

    return {
      urlToTarget,
      indefiniteArticle: "the",
      targetDescription: `${warningTitle} warning`,
    };
  },
  [ActivityTargetType.REPETITIVE_LOSS]: ({}) => {
    return {
      urlToTarget: null,
      indefiniteArticle: "the",
      targetDescription: "Repetitive Loss Data",
    };
  },
  [ActivityTargetType.SUBMISSION]: ({
    activity,
    address,
    propertyId,
    lat,
    lng,
  }) => {
    if (
      [ActivityType.INCLUDED, ActivityType.EXCLUDED].includes(activity.type)
    ) {
      const urlToTarget = pipLink({
        address,
        propertyId,
        lat,
        lng,
        tab: ADDRESS_PANEL_TAB_NAME.RECORDS,
        recordTab: RECORDS_TAB_NAME.IMPROVEMENTS,
      });
      return {
        urlToTarget,
        indefiniteArticle: "the",
        targetDescription: "SI/SD summary",
        action:
          activity.type === ActivityType.EXCLUDED
            ? `excluded an SI/SD record from`
            : `included an SI/SD record for`,
      };
    }

    const urlToTarget = activity.targetInfo.id
      ? {
          pathname: buildLink("viewSubmission", {
            submissionId: activity.targetInfo.id,
          }),
        }
      : null;

    const targetDescription = isSubmissionTarget(activity.target)
      ? activity.target.submissionTypeVersion.submissionType.name
      : // A deleted submission activity will have submission type name stored on targetInfo
        activity.targetInfo.submissionTypeName!;

    // Because submission type names are dynamic, we aren't using indefinite articles
    // in the display, as to prevent gramatical errors
    const indefiniteArticle = "";
    return {
      urlToTarget,
      targetDescription,
      indefiniteArticle,
    };
  },
  [ACTIVITY_TARGET_TYPE.SAVED_VIEW]: ({ activity }) => {
    return {
      urlToTarget: null,
      indefiniteArticle: "the",
      targetDescription: activity.targetInfo.savedViewDescription!,
    };
  },
} as const;

const Message = ({
  feedSource,
  activity,
  latitude,
  longitude,
  fullAddress,
  propertyId,
  linkToProperty,
}: MessageProps) => {
  const { user, admin, account } = React.useContext(AuthContext);

  const trackClick = () => {
    track("Clicked link in activity feed", {
      userId: user?.id ?? admin?.id,
      userEmail: user?.email ?? admin?.email,
      source: feedSource,
    });
  };

  const location = useLocation();
  const messagePartBuilder =
    MessagePartBuilders[activity.targetType as ActivityTargetType];

  const { urlToTarget, targetDescription, action, indefiniteArticle } =
    messagePartBuilder({
      activity,
      targetId: activity.targetInfo.id ?? undefined,
      lat: latitude?.toString() ?? null,
      lng: longitude?.toString() ?? null,
      address: fullAddress,
      propertyId,
      accountId: activity.accountId,
      subdomain: account!.publicPortal.subdomain,
      feedSource,
    });

  let linkToTarget: ReactNode;

  const targetDeleted = isTargetDeleted(activity);

  if (!targetDeleted && urlToTarget !== null) {
    if (activity.type === ActivityType.SHARED) {
      const name =
        activity.targetInfo.recipientName ??
        `${activity.targetInfo.recipientFirstName} 
          ${activity.targetInfo.recipientLastName}`;
      linkToTarget = (
        <>
          <StyledAnchor
            href={urlToTarget?.pathname}
            onClick={trackClick}
            target="_blank"
          >
            {targetDescription}
          </StyledAnchor>{" "}
          with {name}
        </>
      );
    } else {
      linkToTarget = (
        <StyledLink
          to={{
            ...urlToTarget,
            state: { prevLocation: getCurrentURL(location) },
          }}
          onClick={trackClick}
        >
          {targetDescription}
        </StyledLink>
      );
    }
  }

  let propertyLinkText;

  if (fullAddress) {
    propertyLinkText = fullAddress;
  } else if (latitude && longitude) {
    propertyLinkText = formatCoordinates({ latitude, longitude });
  }

  const propertyLink = propertyLinkText && linkToProperty && (
    <>
      {[
        ActivityType.EXCLUDED,
        ActivityType.INCLUDED,
        ActivityType.HID,
        ActivityType.DISPLAYED,
      ].includes(activity.type)
        ? "at"
        : "for"}{" "}
      <StyledLink
        to={{
          state: { prevLocation: getCurrentURL(location) },
          ...pipLink({
            address: fullAddress,
            propertyId,
            lat: latitude?.toString() ?? null,
            lng: longitude?.toString() ?? null,
          }),
        }}
        onClick={trackClick}
      >
        {propertyLinkText}
      </StyledLink>
    </>
  );

  return (
    <>
      {`${action ?? activity.type} `} {`${indefiniteArticle} `}{" "}
      {linkToTarget ?? targetDescription} {propertyLink}
    </>
  );
};

export default Message;
