import React, { ReactNode } from "react";

import { Title } from "./Typography";

import {
  Container,
  Header,
  TabNav,
  Wrapper,
  SectionWrapper,
  TabLink,
} from "./__styles__/Layout";

type Children = ReactNode;
type LayoutProps<T = {}> = { children: Children; className?: string } & T;

export const Section = ({
  children,
  title,
  className,
}: LayoutProps<{ title?: string }>) => (
  <SectionWrapper className={className}>
    {!!title && (
      <Title size="default" type="regular" style={{ marginBottom: "32px" }}>
        {title}
      </Title>
    )}
    {children}
  </SectionWrapper>
);

type Tab = { path: string; name: string };

export default ({
  title,
  tabs,
  children,
  className,
}: LayoutProps<{ title?: string; tabs?: Array<Tab> }>) => (
  <Wrapper className={className}>
    <Header>
      <Container>
        {title && (
          <Title size="large" type="regular" style={{ marginBottom: "32px" }}>
            {title}
          </Title>
        )}
        {!!tabs && (
          <TabNav>
            {tabs.map(tab => (
              <TabLink
                to={{ pathname: tab.path, state: { scrollToTop: false } }}
                key={tab.path}
              >
                {tab.name}
              </TabLink>
            ))}
          </TabNav>
        )}
      </Container>
    </Header>
    <Container>{children}</Container>
  </Wrapper>
);
