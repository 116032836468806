import React, { useEffect, useMemo } from "react";
import { LayerConfig, LayerHookBuilder, LayerHook } from "./types";
import MeasureLayer from "../layers/measureTool";

export type MeasureToolLayerConfig = LayerConfig<
  never,
  { longitude: number; latitude: number } | undefined
>;

export const measureHook: LayerHookBuilder<MeasureToolLayerConfig> = ({
  config,
  helpers,
}) => {
  useEffect(() => {
    if (config.interactive?.click) {
      helpers.emit({ type: "setCursor", data: "crosshair" });
    } else {
      helpers.emit({ type: "setCursor", data: "grab" });
    }
  }, [config.interactive?.click]);

  return useMemo<LayerHook>(() => {
    return {
      // we want the tool to always hover above everything to intercept all clicks
      zIndex: -1,
      render: () => {
        return <MeasureLayer key="measure-tool" />;
      },
      canHandleHover: () => !!config.interactive?.hover,
      canHandleClick: () => !!config.interactive?.click,
      onClick: ({ event }) => {
        if (event.target.className === "overlays") {
          if (event.lngLat[0] && event.lngLat[1]) {
            config.onClick?.({
              longitude: event.lngLat[0],
              latitude: event.lngLat[1],
            });
          } else {
            config.onClick?.(undefined);
          }
        }
        return true;
      },
    };
  }, [config]);
};
