import { mapValues, merge, filter } from "lodash";
import React, { createContext, ReactNode, useState } from "react";
import {
  MapsForAccountQuery,
  PropertyWarningGroup,
} from "../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import {
  MeasureToolReducer,
  useMeasureTool,
} from "common-client/utils/useMeasureTool";
import {
  useApproximateBfeTool,
  ApproximateBfeToolReducer,
} from "common-client/utils/useApproximateBfeTool";

type OptionalLayers =
  | "firms"
  | "customMaps"
  | "rasters"
  | "baseMaps"
  | "accountDocumentTypes"
  | "accountPropertyWarningDefinitions";
export type Account = Omit<
  NonNullable<MapsForAccountQuery["account"]>,
  OptionalLayers | "name"
> &
  Partial<Pick<NonNullable<MapsForAccountQuery["account"]>, OptionalLayers>>;

type LayerToggles = Record<string, boolean>;

type PropertyWarningsLayerState = Record<
  `${PropertyWarningGroup}Warnings`,
  LayerToggles
>;

type LayerState = Record<
  | "firms"
  | "panels"
  | "customMaps"
  | "baseMaps"
  | "documents"
  | "baseFloodElevations"
  | "crossSections"
  | "profileBaselines"
  | "rasters",
  LayerToggles
> &
  PropertyWarningsLayerState & {
    topology: { contour: boolean; cbrs: boolean };
    buildings: { properties: boolean };
  };

export type Group = keyof LayerState;
export type WarningGroup = keyof PropertyWarningsLayerState;
export type MultipleSelectionGroup = WarningGroup;
export type GroupId<G extends Group> = KeysOfUnion<LayerState[G]>;
export type FIRM = NonNullable<MapsForAccountQuery["account"]>["firms"][number];
export type Raster = NonNullable<
  MapsForAccountQuery["account"]
>["rasters"][number];

type LayerId = string;

export type LayerContextType = {
  toggleLayer: <G extends Group>(args: {
    group: G;
    id?: GroupId<G>;
    isVisible: boolean;
  }) => void;
  isLayerVisible: <G extends Group>(args: {
    group: G;
    id: GroupId<G>;
  }) => boolean;
  isLayerGroupVisible: <G extends Group>(args: { group: G }) => boolean;
  visibleLayerIdsForGroup: (group: keyof LayerState) => LayerId[];
  visibleFIRM: () => FIRM | undefined;
  visibleRaster: () => Raster | undefined;
  rasters: Array<Raster>;
  mapNonce: string;
  updateMap: () => void;
  measureToolState: ReturnType<typeof useMeasureTool>[0];
  measureToolDispatch: ReturnType<typeof useMeasureTool>[1];
  approximateBfeToolState?: ReturnType<typeof useApproximateBfeTool>[0];
  approximateBfeToolDispatch?: ReturnType<typeof useApproximateBfeTool>[1];
  a11yEnabled: boolean;
  setA11yEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export type CustomMap = NonNullable<Account["customMaps"]>[number];
export type BaseMap = NonNullable<Account["baseMaps"]>[number];

export const LayerContext = createContext<LayerContextType>({} as any);

const defaultsForFirmGroupComponents = (
  firms: Array<FIRM & { visible: boolean }>
) => {
  const firmGroupComponents = [
    "panels",
    "baseFloodElevations",
    "crossSections",
    "profileBaselines",
  ] as const;

  return firmGroupComponents.reduce((defaultsForFirmGroup, componentName) => {
    defaultsForFirmGroup[componentName] = {};

    firms.forEach(firm => {
      const componentVisible =
        firm.visible && !!firm.components[componentName].visibleByDefault;
      defaultsForFirmGroup[componentName][firm.id] = componentVisible;
    });

    return defaultsForFirmGroup;
  }, {} as Record<typeof firmGroupComponents[number], LayerToggles>);
};

export const LayerContextProvider = ({
  account,
  children,
  defaultState = {},
}: {
  account: Account;
  children: ReactNode;
  defaultState?: Partial<LayerState>;
}) => {
  const firms = account.firms ?? [];

  const [layers, updateLayers] = useState<LayerState>(() => {
    const groupByIdAndAssignDefaults = (
      components: Array<{ id: string; isDefault?: boolean }>
    ) => {
      return components.reduce((grouped, { id, isDefault }) => {
        grouped[id] = isDefault ?? false;
        return grouped;
      }, {} as LayerToggles);
    };

    const customMaps = account.customMaps ?? [];
    const baseMaps = account.baseMaps ?? [];
    const rasters = account.rasters ?? [];
    const accountDocumentTypes = account.accountDocumentTypes ?? [];
    const accountPropertyWarningDefinitions =
      account.accountPropertyWarningDefinitions ?? [];

    const firmsById = groupByIdAndAssignDefaults(firms);
    const baseMapsById = groupByIdAndAssignDefaults(baseMaps);
    const rastersById = groupByIdAndAssignDefaults(rasters);
    const customMapsById = groupByIdAndAssignDefaults(customMaps);
    const accountDocumentTypesById = groupByIdAndAssignDefaults(
      accountDocumentTypes.map((accountDocumentType, index) => ({
        ...accountDocumentType,
        isDefault: index === 0,
      }))
    );

    let propertyWarningLayerState: PropertyWarningsLayerState = {
      complianceWarnings: {},
      customWarnings: {},
      improvementWarnings: {},
      preliminaryDamageAssessmentWarnings: {},
      repetitiveLossWarnings: {},
      firmWarnings: {},
    };

    for (const group of Object.values(PropertyWarningGroup)) {
      propertyWarningLayerState[`${group}Warnings`] =
        groupByIdAndAssignDefaults(
          accountPropertyWarningDefinitions.filter(
            accountPropertyWarningDefinition => {
              if (
                group === PropertyWarningGroup.FIRM_WARNING &&
                !accountPropertyWarningDefinition.isCustom
              ) {
                return false;
              }
              return accountPropertyWarningDefinition.group === group;
            }
          )
        );
    }

    return merge(
      {
        firms: firmsById,
        customMaps: customMapsById,
        rasters: rastersById,
        baseMaps: baseMapsById,
        documents: accountDocumentTypesById,
        topology: {
          contour: false,
          cbrs: false,
        },
        buildings: {
          properties: false,
        },
      },
      propertyWarningLayerState,
      defaultsForFirmGroupComponents(
        firms.map(firm => ({ ...firm, visible: firm.isDefault }))
      ),
      defaultState
    );
  });

  const toggleLayer = <G extends Group>({
    group,
    id,
    isVisible,
  }: {
    group: G[] | G;
    id?: GroupId<G>;
    isVisible: boolean;
  }) => {
    let updatedLayers = merge({}, layers);
    let groups = Array.isArray(group) ? group : [group];

    for (const group of groups) {
      const warningGroups = Object.values(PropertyWarningGroup).map(
        group => `${group}Warnings` as WarningGroup
      );
      // these groups allow for multiple toggles within the same group
      const allowMultipleSelectionGroups: Group[] = [
        ...warningGroups,
        "customMaps",
      ];

      // if the group does not allow for multiple selections disable toggles from the same group
      if (!allowMultipleSelectionGroups.includes(group)) {
        updatedLayers[group as WarningGroup] = mapValues(
          layers[group as WarningGroup],
          () => false
        );
      }
      // Some sections have mutually exclusive toggles,
      // so we disable toggles from the mutually exclusive groups before enabling just the one
      const mutuallyExclusiveGroups: Array<Array<Group>> = [
        ["firms", "rasters"],
        ["rasters", "customMaps"],
        ["documents", ...warningGroups],
      ];

      for (const mutuallyExclusiveGroup of mutuallyExclusiveGroups) {
        if (mutuallyExclusiveGroup.includes(group)) {
          for (const meg of mutuallyExclusiveGroup) {
            if (group != meg) {
              updatedLayers[meg as WarningGroup] = mapValues(
                layers[meg as WarningGroup],
                () => false
              );
            }
          }
        }
      }

      if (id) {
        updatedLayers[group][id] = isVisible as any;

        if (group === "firms") {
          updatedLayers = {
            ...updatedLayers,
            ...defaultsForFirmGroupComponents(
              firms.map(firm => ({
                ...firm,
                visible: firm.id === id && isVisible,
              }))
            ),
          };
        }
      } else {
        updatedLayers[group as MultipleSelectionGroup] = mapValues(
          layers[group as MultipleSelectionGroup],
          () => isVisible
        );
      }
    }
    updateLayers(updatedLayers);
  };

  const visibleLayerIdsForGroup = (group: Group) => {
    const enabledLayer = filter(
      Object.entries(layers[group]),
      ([_layer, enabled]) => enabled
    );

    return enabledLayer.map(layer => layer[0]);
  };

  const visibleFIRM = () => {
    const firmId = visibleLayerIdsForGroup("firms")[0];
    return account.firms?.find(firm => firm.id === firmId);
  };

  const visibleRaster = () => {
    const rasterId = visibleLayerIdsForGroup("rasters")[0];
    return account.rasters?.find(raster => raster.id === rasterId);
  };

  const isLayerVisible = <G extends Group>({
    group,
    id,
  }: {
    group: G;
    id: GroupId<G>;
  }) => {
    return !!layers[group][id];
  };

  const isLayerGroupVisible = <G extends Group>({ group }: { group: G }) => {
    const currentLayers = layers[group as WarningGroup];
    return Object.values(currentLayers).every(layer => layer);
  };

  const [mapNonce, setMapNonce] = useState(uuidv4());
  const updateMap = () => setMapNonce(uuidv4());

  const [measureToolState, measureToolDispatch] = useMeasureTool({
    defaultValue: {
      measureToolMode: "off",
      coordinates: [],
      loading: false,
    },
    useReducer: React.useReducer as MeasureToolReducer,
  });

  const [approximateBfeToolState, approximateBfeToolDispatch] =
    useApproximateBfeTool({
      defaultValue: {
        mode: "off",
        crossSections: [],
        disableMapInteractions: false,
      },
      useReducer: React.useReducer as ApproximateBfeToolReducer,
      showBfeLayers: () => {
        const id = visibleFIRM()?.id;
        toggleLayer({
          group: ["profileBaselines", "crossSections"],
          id,
          isVisible: true,
        });
      },
    });

  const [a11yEnabled, setA11yEnabled] = useState(false);

  const value = {
    toggleLayer,
    isLayerVisible,
    isLayerGroupVisible,
    visibleFIRM,
    visibleRaster,
    visibleLayerIdsForGroup,
    mapNonce,
    updateMap,
    measureToolDispatch,
    measureToolState,
    approximateBfeToolState,
    approximateBfeToolDispatch,
    a11yEnabled,
    setA11yEnabled,
    rasters: account.rasters ?? [],
  };

  return (
    <LayerContext.Provider value={value}>{children}</LayerContext.Provider>
  );
};
