import React, { useContext } from "react";
import { Layer, Source } from "react-map-gl";
import { AuthContext } from "../../../Authorization/AuthContext";

import { LayerId } from "../../LayeredMap";
import { LayerContext, WarningGroup } from "..";
import { PropertyWarningGroup } from "../../../../generated/graphql";
import { colors } from "../../../../stitches.config";

export const propertyBuildingSourceId = "properties-buildings-source";
export const propertyDocumentSourceId = "properties-documents-source";
export const propertyWarningSourceId = "properties-warnings-source";

export const documentsLayerId = "property-documents";
export const buildingsLayerId = "property-buildings";
export const warningsLayerId = "property-warnings";

export const buildingsGroup = "buildings";
export const documentsGroup = "documents";
export const warningsGroups = [
  ...Object.values(PropertyWarningGroup).map(group => `${group}Warnings`),
];

export const interactiveLayerIds: LayerId[] = [
  documentsLayerId,
  warningsLayerId,
  buildingsLayerId,
];

const MIN_PROPERTY_ZOOM = 12;

const PropertiesLayer = ({
  filterHidden = false,
  geocacheParcelId,
}: {
  filterHidden?: boolean;
  geocacheParcelId?: string;
}) => {
  const { account } = useContext(AuthContext);
  const { isLayerVisible, mapNonce, visibleLayerIdsForGroup } =
    useContext(LayerContext);

  const { id: accountId } = account!;

  const buildingTiles = [
    `${window.env.APPLICATION_URL}/api/tiles/buildings/${accountId}/{z}/{x}/{y}?noop=${mapNonce}`,
  ];

  const warningTiles = [
    `${window.env.APPLICATION_URL}/api/tiles/property-warnings/${accountId}/{z}/{x}/{y}?filterHidden=${filterHidden}&noop=${mapNonce}`,
  ];
  const documentTiles = [
    `${window.env.APPLICATION_URL}/api/tiles/documents/${accountId}/{z}/{x}/{y}?filterHidden=${filterHidden}&noop=${mapNonce}`,
  ];

  const buildingFilter = !!geocacheParcelId
    ? ["==", geocacheParcelId, ["get", "geocacheParcelId"]]
    : false;

  const buildingLayerFilter = isLayerVisible({
    group: buildingsGroup,
    id: "properties",
  })
    ? true
    : buildingFilter;

  const documentId = visibleLayerIdsForGroup(documentsGroup)[0];
  const color =
    account!.accountDocumentTypes.find(
      documentType => documentType.id === documentId
    )?.color ?? "white";

  let warningIds: string[] = [];
  for (const group of warningsGroups) {
    warningIds = visibleLayerIdsForGroup(group as WarningGroup);
    if (warningIds.length > 0) {
      break;
    }
  }
  return (
    <>
      <Source
        id={propertyBuildingSourceId}
        tiles={buildingTiles}
        type="vector"
        minzoom={MIN_PROPERTY_ZOOM}
      >
        <Layer
          id={buildingsLayerId}
          type="circle"
          source-layer="src"
          layout={{
            visibility: "visible",
          }}
          filter={[
            "step",
            ["zoom"],
            false,
            MIN_PROPERTY_ZOOM,
            buildingLayerFilter,
          ]}
          paint={{
            "circle-opacity": 0,
            "circle-radius": 5,
            "circle-stroke-width": 1.5,
            "circle-stroke-color": colors.mapOutlineLight.value,
          }}
        />
      </Source>

      <Source
        id={propertyDocumentSourceId}
        tiles={documentTiles}
        type="vector"
        minzoom={MIN_PROPERTY_ZOOM}
      >
        <Layer
          id={documentsLayerId}
          type="circle"
          source-layer="src"
          layout={{
            visibility: documentId ? "visible" : "none",
          }}
          filter={[
            "step",
            ["zoom"],
            false,
            MIN_PROPERTY_ZOOM,
            ["in", documentId ?? "", ["get", "accountDocumentTypeIds"]],
          ]}
          paint={{
            "circle-opacity": 1,
            "circle-stroke-opacity": 1,
            // this is a hack so toggling between documents doesn't cause
            // a visible transition between document colors
            "circle-color": [
              "case",
              ["in", documentId ?? "", ["get", "accountDocumentTypeIds"]],
              color,
              "white",
            ],
            "circle-radius": 5,
            "circle-stroke-width": 1.5,
            "circle-stroke-color": colors.mapOutlineLight.value,
          }}
        />
      </Source>

      <Source
        id={propertyWarningSourceId}
        tiles={warningTiles}
        type="vector"
        minzoom={MIN_PROPERTY_ZOOM}
      >
        <Layer
          id={warningsLayerId}
          type="symbol"
          source-layer="src"
          layout={{
            visibility: "visible",
            "icon-image": ["get", "icon"],
            "symbol-sort-key": ["get", "severity"],
            "icon-allow-overlap": true,
          }}
          filter={[
            "step",
            ["zoom"],
            false,
            MIN_PROPERTY_ZOOM,
            [
              "in",
              ["get", "accountPropertyWarningDefinitionId"],
              ["literal", warningIds],
            ],
          ]}
          paint={{}}
        />
      </Source>
    </>
  );
};

export default PropertiesLayer;
