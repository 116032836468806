import React, { useContext } from "react";

import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { AuthContext } from "../../../Authorization/AuthContext";
import { Property } from "./types";

import {
  getBFEOverriddenDisclaimerText,
  getGeometryDisclaimerText,
} from "common-client/utils/disclaimerOverrides";
import Disclaimer from "../../../Common/Disclaimer";

import { BasePropertyAttribute } from "common-client/generated/graphql";
import { PropertyOverviewContext } from "../PropertyOverviewContext";

export const GeometryDisclaimer = () => {
  const { account, isGuest } = useContext(AuthContext);
  const { queryGeometryType } = useContext(PropertyOverviewContext);

  const disclaimerText = getGeometryDisclaimerText({
    firmInfo: { queryGeometryType },
    disclaimerOverrides: account?.disclaimerOverrides,
    isPublic: isGuest,
  });

  return <Disclaimer message={disclaimerText} />;
};

export const BFEOverriddenDisclaimer = ({
  firm,
  property,
  accountPropertyAttributes,
}: {
  firm: FIRMWithWarnings;
  property: Property;
  accountPropertyAttributes: Array<
    Pick<BasePropertyAttribute, "id" | "source">
  >;
}) => {
  const disclaimerText = getBFEOverriddenDisclaimerText({
    firm,
    designFloodElevationRule: property?.designFloodElevation ?? null,
    accountPropertyAttributes,
  });

  if (!disclaimerText) return null;

  return <Disclaimer message={disclaimerText} />;
};
