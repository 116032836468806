import React from "react";
import { Box } from "../../../Common/__styles__/Layout";
import {
  Header,
  HeaderTitle,
  HelperText,
  SectionTitle,
} from "../../__styles__/Content";
import Divider from "../../../Common/Divider";
import DFERulesTable from "./DFERulesTable";
import { useUpsertDfeRuleModal } from "./upsertDfeRuleModal";
import { AuthContext } from "../../../Authorization/AuthContext";
import {
  DfeRule,
  Raster,
  SisdRule,
  useCreateDfeRuleMutation,
  useCreateSisdRuleMutation,
  useGetAccountPropertyDataSettingsQuery,
  useGetAccountPropertyInformationSectionsQuery,
} from "../../../../generated/graphql";
import SISDRulesTable from "./SISDRulesTable";
import {
  PropertyDataSettingsButton,
  PropertyDataSettingsSection,
} from "./__styles__";
import { useUpsertSisdRuleModal } from "./upsertSisdRuleModal";
import { OverviewSectionsTable } from "./OverviewSections/Table";
import { useCreateOverviewSectionModal } from "./createOverviewSectionModal";

export type RasterOptions = Array<
  Pick<Raster, "id" | "layerName"> & {
    bandMappings: Pick<Raster["bandMappings"], "bandNumber">;
  }
>;

const PropertyData = () => {
  const { account } = React.useContext(AuthContext);

  const { data, error, loading, previousData, refetch } =
    useGetAccountPropertyDataSettingsQuery({
      variables: { accountId: account!.id },
    });

  const { data: sectionData, refetch: refetchSections } =
    useGetAccountPropertyInformationSectionsQuery({
      variables: { accountId: account!.id },
    });

  const [createDfeRule] = useCreateDfeRuleMutation();

  const [showCreateOverviewSectionModal] = useCreateOverviewSectionModal({
    onUpdate: refetchSections,
  });

  const [showUpsertDfeRuleModal] = useUpsertDfeRuleModal({
    onSubmit: createDfeRule,
    onUpdate: refetch,
  });

  const [createSisdRule] = useCreateSisdRuleMutation();
  const [showUpsertSisdRuleModal] = useUpsertSisdRuleModal({
    onSubmit: createSisdRule,
    onUpdate: refetch,
    filterOptions: data?.account?.sisdFilterOptions ?? [],
  });

  if (error) {
    return <div />;
  }

  const dfeRuleDefinition: { rules: Array<DfeRule> } | undefined = {
    rules: (data?.account?.dfeRuleDefinition[0]?.rules ?? []) as Array<DfeRule>,
  };
  const sisdRuleDefinition: { rules: Array<SisdRule> } | undefined = {
    rules: (data?.account?.sisdRuleDefinition[0]?.rules ??
      []) as Array<SisdRule>,
  };
  const previousDfeRuleDefinition: { rules: Array<DfeRule> } | undefined = {
    rules: (previousData?.account?.dfeRuleDefinition[0]?.rules ??
      []) as Array<DfeRule>,
  };
  const previousSisdRuleDefinition: { rules: Array<SisdRule> } | undefined = {
    rules: (previousData?.account?.sisdRuleDefinition[0]?.rules ??
      []) as Array<SisdRule>,
  };

  return (
    <Box>
      <div>
        <Header accountSettings>
          <HeaderTitle accountSettings>Property data settings</HeaderTitle>
        </Header>

        <PropertyDataSettingsSection propertyAttributes>
          <div>
            <div>
              <PropertyDataSettingsButton
                styleVariant="outlineLight"
                size="small"
                leftIconName="plus"
                onClick={showCreateOverviewSectionModal}
              >
                Add section
              </PropertyDataSettingsButton>

              <SectionTitle>Overview sections</SectionTitle>
            </div>
            <HelperText>
              {account?.publicPortal.enabled
                ? "Update the attributes for each overview section displayed on both the internal and public property information panels."
                : "Update the attributes for each overview section displayed on the property information panel."}
            </HelperText>
          </div>
          <OverviewSectionsTable
            accountSections={sectionData?.account?.propertyInformationSections}
            ruleDefinition={dfeRuleDefinition}
            onUpdate={refetchSections}
          />
        </PropertyDataSettingsSection>
      </div>
      <Divider margins="0px 24px 0px 24px" />
      <PropertyDataSettingsSection dfeRules>
        <div>
          <PropertyDataSettingsButton
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={() =>
              showUpsertDfeRuleModal({
                referenceAttributes:
                  data?.account?.dfeReferencePropertyAttributes,
              })
            }
          >
            Add DFE rule
          </PropertyDataSettingsButton>
          <SectionTitle>Design flood elevation logic</SectionTitle>
          <HelperText>
            Add and/or edit DFE logic rules that impact properties within
            specific flood zones. Note that if a property has intersecting
            rules, we will compare logic rules to assign the most restrictive.
          </HelperText>
        </div>
        <div></div>
        <DFERulesTable
          currentData={{
            dfeReferencePropertyAttributes:
              data?.account?.dfeReferencePropertyAttributes,
            ruleDefinition: dfeRuleDefinition,
          }}
          previousData={{ ruleDefinition: previousDfeRuleDefinition }}
          loading={loading}
          onUpdate={refetch}
        />
      </PropertyDataSettingsSection>
      <div>
        <Divider margins="0px 24px 0px 24px" />
        <PropertyDataSettingsSection sisdRules>
          <div>
            <PropertyDataSettingsButton
              styleVariant="outlineLight"
              size="small"
              leftIconName="plus"
              onClick={() => {
                showUpsertSisdRuleModal({
                  maxPriority: sisdRuleDefinition.rules.length + 1,
                  filterOptions: data?.account?.sisdFilterOptions ?? [],
                });
              }}
            >
              Add SI/SD rule
            </PropertyDataSettingsButton>
            <SectionTitle>Substantial improvement / damage logic</SectionTitle>{" "}
            <HelperText>
              Add and/or edit SI/SD logic rules that impact properties with
              specific property attributes (i.e. zones).
            </HelperText>
          </div>
          <SISDRulesTable
            currentData={{
              ruleDefinition: sisdRuleDefinition,
            }}
            previousData={{
              ruleDefinition: previousSisdRuleDefinition,
            }}
            filterOptions={data?.account?.sisdFilterOptions ?? []}
            loading={loading}
            onUpdate={refetch}
          />
        </PropertyDataSettingsSection>
      </div>
    </Box>
  );
};

export default PropertyData;
