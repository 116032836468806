import React from "react";
import { FieldProps } from "@rjsf/utils";
import { get } from "lodash";

import { MultiselectBubble } from "../../Inputs/MultiselectBubble";
import { Input } from "../__styles__/Inputs";

export const TagsInput = (props: FieldProps) => {
  const existingSelectedItems = props.formData;
  const items = get(props.schema, "items.enum") as Array<string>;

  return (
    <Input>
      <MultiselectBubble
        items={items}
        selectedItems={existingSelectedItems}
        onChange={props.onChange}
        name={props.name}
        required={props.required && !props.disabled}
        label={props.schema.title}
        error={props.rawErrors?.join(", ")}
        description={props.uiSchema!["ui:description"]}
        disabled={props.disabled}
      />
    </Input>
  );
};
