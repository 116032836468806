import React, { useContext } from "react";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";
import { Button } from "../Common/Button";
import Modal from "../Common/Modal";
import {
  GetUserInvitesAndUsersQuery,
  useUpdateUserPermissionsMutation,
  UserRoleName,
} from "../../generated/graphql";
import { track } from "../../utils/tracking";
import { PiiPermissionsSelect, UserRoleSelect } from "./UserPermissionSelects";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
  SecondaryButtons,
} from "../Common/__styles__/Modal";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../Authorization/AuthContext";
import { StyledLink } from "../Common/__styles__/Typography";

type User = Omit<
  GetUserInvitesAndUsersQuery["usersAndInvites"]["data"][number],
  "suspended" | "__typename"
>;

export interface UpdateUserPermissionsModalHookArgs {
  onCancel: () => void;
  onUpdate: () => void;
  user: User;
}
export const useUpdateUserPermissionsModal = ({
  onCancel,
  onUpdate,
  user,
}: UpdateUserPermissionsModalHookArgs) => {
  const [showUpdateUserPermissionsModal, hideUpdateUserPermissionsModal] =
    useModal(
      () => (
        <Modal
          onRequestClose={() => {
            onCancel();
          }}
        >
          <UpdateUserPermissionsForm
            onCancel={onCancel}
            onUpdate={onUpdate}
            user={user}
          />
        </Modal>
      ),
      [user]
    );

  return [
    showUpdateUserPermissionsModal,
    hideUpdateUserPermissionsModal,
  ] as const;
};

type UpdateUserPermissionsStructure = {
  role: UserRoleName;
  canViewPersonalIdentifiableInformation: boolean;
};

const UpdateUserPermissionsForm = ({
  user,
  onCancel,
  onUpdate,
}: UpdateUserPermissionsModalHookArgs) => {
  const { addToast } = useToasts();
  const { account } = useContext(AuthContext);

  const { control, handleSubmit } = useForm<UpdateUserPermissionsStructure>({
    defaultValues: {
      role: user.role.name as UserRoleName,
      canViewPersonalIdentifiableInformation:
        user.canViewPersonalIdentifiableInformation,
    },
  });

  const [updateUserPermissions] = useUpdateUserPermissionsMutation({
    onCompleted: () => {
      track("Role updated", {
        modifiedUserId: user.id,
        modifiedUserEmail: user.email,
      });
      addToast(
        `${user.firstName} ${user.lastName}'s role was changed successfully.`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      onUpdate();
      onCancel();
    },
    onError: () => {
      addToast(
        `There was an error updating this user. Please try again. If the problem persists, please email us at support@withforerunner.com`,
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
  });

  const formOnSave = async (data: UpdateUserPermissionsStructure) => {
    await updateUserPermissions({ variables: { data, userId: user.id } });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Change role</h1>
        <h2>
          Edit the role for {user.firstName} {user.lastName} ({user.email})
        </h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <UserRoleSelect onChange={field.onChange} value={field.value} />
            )}
          />
          {account?.repetitiveLossEnabled && (
            <React.Fragment>
              <Controller
                control={control}
                name={"canViewPersonalIdentifiableInformation"}
                render={({ field }) => (
                  <PiiPermissionsSelect
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </React.Fragment>
          )}
        </ContentSection>
        <ButtonSection>
          <SecondaryButtons>
            <StyledLink
              href="https://forerunner.helpscoutdocs.com/article/18-user-and-account-settings"
              target="_blank"
              onClick={() => track("User role documentation clicked")}
            >
              Learn more about our user roles
            </StyledLink>
          </SecondaryButtons>
          <PrimaryButtons>
            <Button onClick={onCancel} size="medium" styleVariant="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(formOnSave)}
              size="medium"
              styleVariant="primary"
            >
              Save
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default UpdateUserPermissionsForm;
