import React, { ReactNode, useContext } from "react";
import { FIRMInfoRow } from "../FIRMInfo/FIRMTable/Rows/FIRMInfoRow";
import {
  FloodInfoBasePropertyAttribute,
  Property,
} from "common-client/utils/firms";
import { LayerContext } from "../../../Maps/layers";
import { RESOURCE_NAME } from "common/authorization";
import { RasterUnitOfMeasurement } from "../../../../generated/graphql";
import { StoreRasterMeasureTool } from "../../../Maps/MeasureTool";
import { RASTER_UNITS_SIGNIFIER } from "common/constants";
import { AuthContext } from "../../../Authorization/AuthContext";
import { RenderMode } from "../FIRMInfo/types";
import { PropertyOverviewTooltip } from "./PropertyOverviewTooltip";

export const RasterRow = ({
  accountPropertyAttribute,
  size,
  property,
}: {
  accountPropertyAttribute: Omit<FloodInfoBasePropertyAttribute, "name">;
  size: RenderMode;
  property?: Maybe<Pick<Property, "id" | "additionalFields">>;
}) => {
  const { authorized } = useContext(AuthContext);
  const { measureToolDispatch } = useContext(LayerContext);

  const canUpdateProperty = authorized({
    resource: RESOURCE_NAME.PROPERTY,
    permission: "update",
  });

  const rasterClick = () => {
    measureToolDispatch({
      type: "setMeasureMode",
      data: {
        measureToolMode: "raster",
        dataMode: "store",
        render: () => (
          <StoreRasterMeasureTool
            attribute={accountPropertyAttribute}
            property={property!}
          />
        ),
      },
    });
  };

  const wrapper = () => {
    return ({ children }: { children?: ReactNode }) => {
      if (property && canUpdateProperty) {
        return (
          <a data-testid={`edit-raster`} onClick={() => rasterClick()}>
            {children}
          </a>
        );
      } else {
        return <>{children}</>;
      }
    };
  };

  const value = property?.additionalFields[accountPropertyAttribute.id]?.value;
  const displayValue = () => {
    if (value) {
      return `${value}${
        RASTER_UNITS_SIGNIFIER[
          property.additionalFields[accountPropertyAttribute.id]!
            .units as RasterUnitOfMeasurement
        ]
      }`;
    }
    if (property && canUpdateProperty) {
      return "Add";
    }
    return null;
  };

  return (
    <FIRMInfoRow
      {...accountPropertyAttribute}
      size={size}
      property={accountPropertyAttribute.id}
      valueForFIRM={displayValue}
      tooltip={PropertyOverviewTooltip({
        tooltipMarkdown: accountPropertyAttribute.tooltip,
      })}
      wrap={wrapper()}
    />
  );
};
