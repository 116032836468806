import React, { createRef, useContext, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useStatusToasts } from "../../hooks/useStatusToasts";

import {
  SubmissionIntakeSource,
  useCreateSubmissionMutation,
  useGetPropertyForSubmissionQuery,
  useGetSubmissionTypeQuery,
} from "../../generated/graphql";
import FullPageFormLayout from "../Common/FullPageFormLayout";
import { FormComponent, FormDataType } from "./Form";
import { buildLink } from "common/routing";
import { setTitle } from "../../utils/title";
import { accountTitle as getAccountTitle } from "../Guest/utils";
import SubmissionSuccess from "./SubmissionSuccess";
import { track } from "../../utils/tracking";

import { DescriptionContainer } from "./__styles__/CreateSubmission";
import { AuthContext } from "../Authorization/AuthContext";
import { IMPROVEMENT_KINDS } from "common/constants";
import SubmissionHeader from "./SubmissionHeader";
import { generateUpsertErrorMessage } from "./utils";

interface CreateSubmissionParams {
  propertyId?: string;
  submissionTypeId: string;
}

export interface CreateSubmissionProps
  extends RouteComponentProps<CreateSubmissionParams> {}

type HistoryLocationState = {
  prevLocation?: string;
  search?: string;
  formData?: any;
  relatedSubmissionId?: string;
};

export const CreateSubmission = ({
  propertyId,
  submissionTypeId,
}: CreateSubmissionParams) => {
  const history = useHistory();
  const location = useLocation<Maybe<HistoryLocationState>>();

  const { addFailureToast, addSuccessToast } = useStatusToasts();
  const [disabled, setDisabled] = useState(true);
  const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false);
  const accountTitle = getAccountTitle();
  const { user, admin, isGuest } = useContext(AuthContext);

  const { data, loading, error } = useGetSubmissionTypeQuery({
    variables: { submissionTypeId },
  });

  const { data: propertyData, loading: propertyLoading } =
    useGetPropertyForSubmissionQuery({
      variables: { propertyId: propertyId!, isGuest },
      skip: !propertyId,
    });

  const submissionType = data?.submissionType;
  const [createSubmission, { loading: createLoading }] =
    useCreateSubmissionMutation({
      onCompleted: data => {
        const submission = data.createSubmission;
        track("Submission created", {
          submissionId: submission.id,
          "Submission Category": submissionType?.category,
          "Submission Type": submissionType?.name,
        });

        if (submissionType?.intakeSource === SubmissionIntakeSource.EXTERNAL) {
          setShowSubmissionSuccess(true);
        }

        if (submissionType?.intakeSource === SubmissionIntakeSource.INTERNAL) {
          if (data?.createSubmission.summaries?.length) {
            history.push({
              pathname: buildLink("submissionSummary", {
                submissionId: submission.id,
              }),
              state: { prevLocation },
            });
          } else {
            history.push(prevLocation, { forceRefresh: true });
          }
          addSuccessToast(submissionType.successMessage!);
        }
      },
      onError: error => {
        const message = generateUpsertErrorMessage(error);
        addFailureToast(message);
      },
    });

  if (loading || propertyLoading || error || !data?.submissionType) {
    return <div />;
  }

  const initialFormData = location.state?.formData;

  if (data.submissionType.intakeSource === SubmissionIntakeSource.EXTERNAL) {
    setTitle(`Create Submission | ${accountTitle}`);
  }
  const submitFormRef = createRef<HTMLButtonElement>();
  const versionId = data.submissionType.currentVersion.id;

  const baseLocation =
    data.submissionType.intakeSource === SubmissionIntakeSource.EXTERNAL
      ? "guestHome"
      : "map";

  const prevLocation = `${
    location.state?.prevLocation ?? buildLink(baseLocation)
  }${location.state?.search ?? ""}`;

  const handleSubmit = async (formData: FormDataType) => {
    const relatedSubmissionId = location.state?.relatedSubmissionId ?? null;

    await createSubmission({
      variables: {
        data: {
          formData,
          versionId,
          propertyId,
          relatedSubmissionId,
        },
        isPublic: isGuest,
      },
    });
  };

  const handleOnChange = ({ isValid }: { isValid: boolean }) => {
    setDisabled(!isValid);
  };

  const property = propertyData?.property;
  const parcel = property?.parcel;

  let componentToDisplay: JSX.Element;
  if (showSubmissionSuccess) {
    componentToDisplay = (
      <SubmissionSuccess
        preamble={data.submissionType.preamble!}
        postamble={data.submissionType.postamble!}
      />
    );
  } else {
    componentToDisplay = (
      <FullPageFormLayout
        subtitle={`Add new ${data.submissionType.name}`}
        prevLocation={prevLocation}
        onSave={() => {
          submitFormRef.current!.click();
        }}
        buttonText="Submit"
        buttonDisabled={disabled || createLoading}
        buttonLoading={createLoading}
      >
        <div>
          <SubmissionHeader
            submissionType={data.submissionType}
            property={propertyData?.property}
          >
            {data.submissionType.description && (
              <DescriptionContainer
                markdown={data.submissionType.description}
              />
            )}
          </SubmissionHeader>
          <FormComponent
            submissionType={data.submissionType}
            onSubmit={handleSubmit}
            onChange={handleOnChange}
            submitFormRef={submitFormRef}
            initialFormData={initialFormData}
            defaultValues={{
              property: {
                improvementValue:
                  property?.improvementValue || parcel?.improvementValue,
                sisd: {
                  adjustmentRatio: property?.sisd.rule?.adjustmentRatio,
                  defaultExclude: property?.sisd.rule?.defaultExclude,
                  costType: IMPROVEMENT_KINDS.IMPROVEMENT,
                },
              },
              parcel,
              user: user ?? admin,
            }}
          />
        </div>
      </FullPageFormLayout>
    );
  }

  return componentToDisplay;
};

const CreateSubmissionContainer = ({ match }: CreateSubmissionProps) => {
  const { submissionTypeId, propertyId } = match.params;

  return (
    <CreateSubmission
      submissionTypeId={submissionTypeId}
      propertyId={propertyId}
    />
  );
};

export default CreateSubmissionContainer;
