import React from "react";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { Button } from "../../Common/Button";

import "./__styles__/DeleteConfirmation.scss";
import { track } from "../../../utils/tracking";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import {
  Improvement,
  useDeleteSubmissionMutation,
} from "../../../generated/graphql";
import { omit } from "lodash";

interface DeleteImprovementConfirmationProps {
  improvement: Pick<Improvement, "id" | "date" | "value">;
  address?: Maybe<string>;
  onCancel: () => void;
  onComplete: () => void;
}

const DeleteImprovementConfirmation = ({
  improvement,
  address,
  onCancel,
  onComplete,
}: DeleteImprovementConfirmationProps) => {
  const { addToast } = useToasts();

  const [deleteSubmission, { loading }] = useDeleteSubmissionMutation({
    onCompleted: () => {
      onComplete();
      addToast("Your improvement was deleted", {
        appearance: "success",
        autoDismiss: true,
      });

      track("Deleted improvement", {
        address,
        ...omit(improvement, "property"),
      });
    },
    onError: () => {
      addToast(
        "There was an issue deleting your improvement. Please try again. If the problem persists, please email us at support@withforerunner.com",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
  });

  const handleDelete = async () => {
    if (loading) return;

    await deleteSubmission({ variables: { submissionId: improvement.id } });
  };

  return (
    <Container width={"narrow"}>
      <HeaderSection>
        <h1>Delete Improvement Project</h1>
      </HeaderSection>
      <ContentSection style={{ textAlign: "center" }}>
        <span styleName="icon" />
        <p>
          Are you certain you would like to delete the following Improvement
          Project:
        </p>
        <p>
          Date: <strong>{moment(improvement.date).format("l")}</strong> <br />
          Value: <strong>${improvement.value.toLocaleString()}</strong>
        </p>
        <p>Warning: this action is irreversible</p>
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" size="medium" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            styleVariant="alert"
            size="medium"
            onClick={handleDelete}
            disabled={loading}
          >
            Delete Improvement
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

export default DeleteImprovementConfirmation;
