import React from "react";
import Icon, { Icons, ICON_COLORS } from "./Icons";

import { Link } from "react-router-dom";
import {
  LeftContainer,
  RightContainer,
  Subtitle,
  SubtitleContainer,
  VerticalDivider,
  Header,
  CloseButton,
} from "./__styles__/HeaderNav";

type HeaderNavProps = {
  subtitle?: string;
  prevLocation?: { pathname: string; search?: string } | string;
  rightContainer?: JSX.Element;
  style?: React.CSSProperties;
  onClose?: () => void;
  dark?: boolean;
};

const HeaderNav = ({
  subtitle,
  prevLocation,
  rightContainer,
  onClose,
  dark,
  ...props
}: HeaderNavProps) => {
  let closeButton = null;

  if (onClose) {
    closeButton = (
      <CloseButton onClick={onClose}>
        <Icon
          icon={Icons.CLOSE}
          color={dark ? ICON_COLORS.WHITE : ICON_COLORS.GREY}
          hoverable
        />
      </CloseButton>
    );
  }

  if (prevLocation) {
    closeButton = (
      <CloseButton>
        <Link to={prevLocation}>
          <Icon
            icon={Icons.CLOSE}
            color={dark ? ICON_COLORS.WHITE : ICON_COLORS.GREY}
            hoverable
          />
        </Link>
      </CloseButton>
    );
  }

  return (
    <Header data-testid="header-nav" {...props} dark={dark}>
      <LeftContainer>
        {closeButton}
        {subtitle && (
          <SubtitleContainer>
            <VerticalDivider />
            <Subtitle>{subtitle}</Subtitle>
          </SubtitleContainer>
        )}
      </LeftContainer>
      <RightContainer>{rightContainer}</RightContainer>
    </Header>
  );
};

export default HeaderNav;
