import React, { useContext } from "react";
import { useModal } from "react-modal-hook";
import { useToasts } from "react-toast-notifications";

import Modal from "../Common/Modal";
import { Button } from "../Common/Button";
import { Text } from "../Inputs/react-hook-form";
import { useInviteUserMutation, UserRoleName } from "../../generated/graphql";
import { PiiPermissionsSelect, UserRoleSelect } from "./UserPermissionSelects";
import { track } from "../../utils/tracking";

import {
  ButtonSection,
  Container,
  InputRow,
  ContentSection,
  PrimaryButtons,
  SecondaryButtons,
  HeaderSection,
  FormSection,
} from "../Common/__styles__/Modal";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../Authorization/AuthContext";
import { isValidEmail } from "common/utils/strings";
import { StyledLink } from "../Common/__styles__/Typography";

interface NewUserInviteModalHook {
  onSubmit: () => void;
  onCancel: () => void;
}

export const useNewUserInviteModal = ({
  onSubmit,
  onCancel,
}: NewUserInviteModalHook) => {
  const [showNewUserInviteModal, hideNewUserInviteModal] = useModal(
    () => (
      <Modal onRequestClose={onCancel}>
        <NewUserInviteForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal>
    ),
    [onSubmit]
  );

  return [showNewUserInviteModal, hideNewUserInviteModal] as const;
};

type NewUserInviteStructure = {
  firstName: string;
  lastName: string;
  email: string;
  roleName: UserRoleName;
  canViewPersonalIdentifiableInformation: boolean;
};

const NewUserInviteForm = ({ onCancel, onSubmit }: NewUserInviteModalHook) => {
  const { addToast } = useToasts();
  const { account } = useContext(AuthContext);

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = useForm<NewUserInviteStructure>({
    defaultValues: {
      roleName: UserRoleName.VIEW_ONLY,
      canViewPersonalIdentifiableInformation: false,
    },
  });

  const [newUserInvite, { loading }] = useInviteUserMutation({
    onCompleted: response => {
      const invite = response.inviteUser;

      addToast(
        `${invite?.firstName} ${invite?.lastName} was sent an invitation to create their account`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );

      const value = getValues();
      track("New user invited", {
        email: value.email,
        roleName: value.roleName,
      });

      onSubmit();
    },

    onError: error => {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });

  const formOnSave = async (data: NewUserInviteStructure) => {
    if (loading) return;

    await newUserInvite({ variables: data });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Create new user</h1>
        <h2>
          Enter the name and email address of the user you'd like to invite.
        </h2>
      </HeaderSection>
      <FormSection onSubmit={onCancel}>
        <ContentSection withBottomDivider>
          <InputRow>
            <Text
              label="First name"
              error={errors.firstName?.message}
              required
              {...register("firstName", {
                required: "First name is required",
              })}
            />
            <Text
              label="Last name"
              error={errors.lastName?.message}
              required
              {...register("lastName", {
                required: "Last name is required",
              })}
            />
          </InputRow>

          <InputRow>
            <Text
              label="Email"
              error={errors.email?.message}
              required
              {...register("email", {
                validate: val => {
                  if (!isValidEmail(val)) {
                    return "A valid email is required";
                  }
                  return;
                },
              })}
            />
          </InputRow>
        </ContentSection>
        <ContentSection>
          <Controller
            control={control}
            name="roleName"
            render={({ field }) => (
              <UserRoleSelect onChange={field.onChange} value={field.value} />
            )}
          />
          {account?.repetitiveLossEnabled && (
            <Controller
              control={control}
              name="canViewPersonalIdentifiableInformation"
              render={({ field }) => (
                <PiiPermissionsSelect
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          )}
        </ContentSection>
        <ButtonSection>
          <SecondaryButtons>
            <StyledLink
              href="https://forerunner.helpscoutdocs.com/article/18-user-and-account-settings"
              target="_blank"
              onClick={() => track("User role documentation clicked")}
            >
              Learn more about our user roles
            </StyledLink>
          </SecondaryButtons>
          <PrimaryButtons>
            <Button
              onClick={onCancel}
              styleVariant="secondary"
              size="medium"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(formOnSave)}
              disabled={loading}
              size="medium"
              styleVariant="primary"
            >
              Save
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default NewUserInviteForm;
