import React, { ComponentProps, forwardRef, Ref, useState } from "react";
import {
  ButtonContentsWrapper,
  LoadingWrapper,
  StyledButton,
} from "./__styles__/Button";
import { Icon, IconNames } from "./Icons/LucideIcons";
import { colors } from "../../stitches.config";

const IconColorMap = {
  primary: "contentPrimaryDark",
  secondary: "contentPrimary",
  outlineLight: "contentPrimary",
  outlineDark: "contentPrimaryDark",
  hoverOutline: "contentPrimary",
  ghost: "contentInfo",
  ghostWhite: "contentPrimaryDark",
  ghostWhiteOutline: "contentPrimaryDark",
  ghostGrey: "contentSecondary",
  ghostAlert: "contentCritical",
  alert: "contentPrimaryDark",
  disabled: "contentDisabled",
} as const;

export type StyleVariant =
  | "primary"
  | "secondary"
  | "outlineLight"
  | "outlineDark"
  | "hoverOutline"
  | "ghost"
  | "ghostWhite"
  | "ghostWhiteOutline"
  | "ghostGrey"
  | "ghostAlert"
  | "alert";

export type ButtonProps = Omit<
  ComponentProps<typeof StyledButton>,
  "css" | "styleVariant"
> & {
  styleVariant: StyleVariant;
  leftIconName?: IconNames;
  loading?: boolean;
  rightIconName?: IconNames;
};

export const Button = forwardRef(
  (
    {
      leftIconName,
      loading,
      rightIconName,
      styleVariant,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const disabledState = props.disabled || loading;
    const [isHovered, setIsHovered] = useState(false);
    const getIconColor = (styleVariant: StyleVariant) => {
      if (props.disabled) {
        return IconColorMap.disabled;
      }

      if (isHovered && styleVariant === "ghost") {
        return "bgButtonPrimaryHover";
      }

      if (
        (isHovered && styleVariant === "ghostWhite") ||
        styleVariant === "ghostWhiteOutline" ||
        styleVariant === "ghostGrey"
      ) {
        return "contentSecondaryDark";
      }

      return IconColorMap[styleVariant];
    };
    const [iconColor, setIconColor] = useState<keyof typeof colors>(
      getIconColor(styleVariant)
    );

    React.useEffect(() => {
      setIconColor(getIconColor(styleVariant));
    }, [isHovered, styleVariant, disabledState]);

    let contents: React.ReactNode;

    if (loading) {
      contents = (
        <LoadingWrapper>
          <Icon iconName="loading" color="contentInfo" size={16} />
        </LoadingWrapper>
      );
    } else {
      contents = (
        <ButtonContentsWrapper>
          {leftIconName && (
            <Icon iconName={leftIconName} color={iconColor} size={16} />
          )}
          {props.children}
          {rightIconName && (
            <Icon iconName={rightIconName} color={iconColor} size={16} />
          )}
        </ButtonContentsWrapper>
      );
    }

    return (
      <StyledButton
        {...props}
        ref={ref}
        styleVariant={styleVariant}
        disabledState={disabledState}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {contents}
      </StyledButton>
    );
  }
);
