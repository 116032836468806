import React, { useContext } from "react";
import { useHistory } from "react-router";
import { RESOURCE_NAME } from "common/authorization";
import { buildLink } from "common/routing";
import { AuthContext } from "../../Authorization/AuthContext";
import DropdownMenu, { ActionsProps } from "../../Inputs/DropdownMenu";
import { PartialInspection } from ".";
import { useDeleteModal } from "../../Common/DeleteModal";
import { useDeleteSubmissionMutation } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { track } from "../../../utils/tracking";

interface EditInspectionDropdownProps {
  onUpdate: () => {};
  inspection: PartialInspection;
  address?: Maybe<string>;
}

const EditInspectionDropdown = ({
  onUpdate,
  inspection,
  address,
}: EditInspectionDropdownProps) => {
  const { authorized } = useContext(AuthContext);
  const { addFailureToast, addSuccessToast } = useStatusToasts();
  const history = useHistory();

  const deleteHandlers = {
    onCompleted: () => {
      onUpdate();
      addSuccessToast("Your inspection was deleted");
      track("Deleted inspection", { address });
    },
    onError: () => {
      addFailureToast(
        "There was an issue deleting your inspection. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    },
  };

  const [deleteInspection] = useDeleteSubmissionMutation(deleteHandlers);

  const [showDeleteInspectionModal] = useDeleteModal({
    headerText: "Delete inspection",
    Content: (
      <p>
        This action <strong>cannot</strong> be undone. This will permanently
        delete the inspection.
      </p>
    ),
    deleteButtonText: "I understand, and want to delete the inspection",
    onDelete: () =>
      deleteInspection({ variables: { submissionId: inspection.id } }),
  });

  const canView = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "view",
  });

  const canDelete = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "delete",
  });

  const actions: Array<ActionsProps> = [
    {
      label: "View",
      disabled: !canView,
      to: {
        pathname: buildLink("viewSubmission", { submissionId: inspection.id }),
        state: {
          prevLocation: history.location.pathname,
          search: location.search,
          address,
        },
      },
      closeOnClick: false,
    },
    {
      label: "Delete",
      disabled: !canDelete,
      variant: "red",
      onClick: showDeleteInspectionModal,
      closeOnClick: true,
    },
  ];

  return <DropdownMenu actions={actions} />;
};

export default EditInspectionDropdown;
