import React, { useContext, useState } from "react";
import { useModal } from "react-modal-hook";

import Modal from "../../../Modal";
import { Button } from "../../../Button";
import {
  SavedView,
  useDeleteSavedViewMutation,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { TableContext } from "../../TableContext";
import { useUpdateSavedView } from "../../utils";

import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../__styles__/Modal";

const DeleteSavedViewModal = ({
  savedView,
  hideModal,
  currentView,
  defaultView,
}: {
  savedView: SavedView;
  hideModal: () => void;
  currentView: SavedView;
  defaultView: SavedView;
}) => {
  const { updateSavedView } = useUpdateSavedView();

  const { addFailureToast, addSuccessToast } = useStatusToasts();

  const [deleteSavedView, { loading }] = useDeleteSavedViewMutation({
    onCompleted: () => {
      const deletedCurrentView = savedView.id === currentView.id;

      if (deletedCurrentView) {
        updateSavedView(defaultView.id);
      }

      hideModal();

      addSuccessToast(`Your table view was successfully deleted`);
    },
    refetchQueries: ["GetSavedViews"],
    awaitRefetchQueries: true,
    onError: error => {
      const errorMessage = error.graphQLErrors
        .map(error => error.message)
        .join("\n");
      addFailureToast(errorMessage);
    },
  });

  return (
    <Container width={"narrow"}>
      <HeaderSection>
        <h1>Delete "{savedView.name}" view?</h1>
      </HeaderSection>
      <ContentSection>
        <p>
          This action will result in the permanent deletion of this table view
          for everyone on the account.
        </p>
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={hideModal} size="medium">
            Cancel
          </Button>
          <Button
            size="medium"
            styleVariant="alert"
            onClick={() => {
              void deleteSavedView({ variables: { id: savedView.id } });
            }}
            disabled={loading}
          >
            I understand, delete this view
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

export const useDeleteSavedViewModal = () => {
  const { defaultView, currentView } = useContext(TableContext);
  const [savedView, setSavedView] = useState<SavedView | null>(null);

  let [showModal, hideModal] = useModal(
    () => (
      <Modal onRequestClose={hideModal}>
        <DeleteSavedViewModal
          savedView={savedView!}
          hideModal={() => {
            hideModal();
            setSavedView(null);
          }}
          defaultView={defaultView!}
          currentView={currentView!}
        />
      </Modal>
    ),
    [defaultView, savedView, currentView]
  );

  const showDeleteSavedViewModal = (savedView: SavedView) => {
    setSavedView(savedView);
    showModal();
  };

  return showDeleteSavedViewModal;
};
