import React, { useContext } from "react";
import { MapFilterInputs, SelectFilterInputs } from "./FilterInputs";
import FilterTargetDropdown from "./FilterTargetDropdown";
import { Button } from "../../../../Common/Button";
import useFilterBuilderState from "./hooks/useFilterBuilderState";
import {
  FilterBuilderClearButton,
  FilterBuilderFooter,
  FilterBuilderInputsWrapper,
  LogicFilterBuilderWrapper,
} from "./__styles__/LogicFilterBuilder";
import { FilterContext, FilterContextStructure } from "../FilterContext";
import { FilterTarget } from "./types";

type LogicFilterBuilderProps = Omit<
  ReturnType<typeof useFilterBuilderState>,
  "removeFilterSummary"
>;

const filterInputByFilterTarget = (
  filterTarget: Maybe<FilterTarget>,
  filterContext: FilterContextStructure
) => {
  const inputType = filterTarget
    ? filterContext.filterTargetMetadata[filterTarget]!.inputType
    : null;
  switch (inputType) {
    case "map-select":
      return MapFilterInputs;
    case "select":
      return SelectFilterInputs;
    default:
      return () => null;
  }
};

const LogicFilterBuilder = ({
  addNewFilter,
  cannotAddNewFilter,
  cannotClearValues,
  changeFilterTarget,
  clearBuilderValues,
  filter,
  filterTarget,
  changeFilterInputsValues,
  selectedFilterTargets,
}: LogicFilterBuilderProps) => {
  const filterContext = useContext(FilterContext);
  const FilterInputs = filterInputByFilterTarget(filterTarget, filterContext);

  return (
    <LogicFilterBuilderWrapper>
      <FilterBuilderInputsWrapper>
        <FilterTargetDropdown
          currentFilterTarget={filterTarget}
          changeFilterTarget={changeFilterTarget}
          clearFilterTarget={clearBuilderValues}
          selectedFilterTargets={selectedFilterTargets}
        />
        <FilterInputs
          values={filter}
          onFilterInputValuesChange={changeFilterInputsValues}
          filterTarget={filterTarget}
        />
      </FilterBuilderInputsWrapper>
      <FilterBuilderFooter>
        <Button
          style={{ padding: "12px" }}
          styleVariant="primary"
          disabled={cannotAddNewFilter}
          onClick={addNewFilter}
          leftIconName="plus"
          size="medium"
        >
          Add filter
        </Button>
        {!cannotClearValues && (
          <FilterBuilderClearButton
            styleVariant="ghostGrey"
            disabled={cannotClearValues}
            onClick={clearBuilderValues}
            size="medium"
          >
            Clear
          </FilterBuilderClearButton>
        )}
      </FilterBuilderFooter>
    </LogicFilterBuilderWrapper>
  );
};

export default LogicFilterBuilder;
