import React, { PropsWithChildren } from "react";
import { ToastProps, AppearanceTypes } from "react-toast-notifications";
import {
  IconWrapper,
  Container,
  Content,
  CloseIconWrapper,
} from "./__styles__/Toast";
import { Icon, IconNames } from "./Icons/LucideIcons";

export default ({
  children,
  appearance = "info",
  transitionDuration,
  onDismiss,
  transitionState,
}: PropsWithChildren<ToastProps> & { appearance?: AppearanceTypes }) => {
  const IconMap = {
    success: "check-circle",
    error: "x-circle",
    info: "alert-circle",
    warning: "alert-circle",
  };

  return (
    <Container
      appearance={appearance}
      transitionState={transitionState}
      style={{ transitionDuration: `${transitionDuration}ms` }}
    >
      <IconWrapper>
        <Icon
          iconName={IconMap[appearance] as IconNames}
          color="contentPrimaryDark"
          size={24}
        />
      </IconWrapper>
      <Content>{children}</Content>
      <CloseIconWrapper>
        <Icon
          iconName={"x"}
          color="contentPrimaryDark"
          size={16}
          cursor="pointer"
          onClick={() => onDismiss()}
        />
      </CloseIconWrapper>
    </Container>
  );
};
