import React, { ReactNode, ComponentType, Fragment, useContext } from "react";
import { find, startCase } from "lodash";
import {
  EditableMode,
  FloodInfoBasePropertyAttribute,
  Property,
  editableInformationForFIRMs,
  isEmptyFIRM,
} from "common-client/utils/firms";
import { RESOURCE_NAME } from "common/authorization";
import { formatDate, formatFeet } from "common/utils/strings";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";

import WarningIcon from "../../../../../images/warning-yellow.svg";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { RenderMode } from "../types";
import { Account } from "./types";
import { Account as AuthAccount } from "../../../../Authorization/types";
import { Blank, FIRMInfoRow } from "./Rows/FIRMInfoRow";
import { DesignFloodElevationRow } from "./Rows/DesignFloodElevationRow";
import { formatNullableBoolean } from "common-client/utils/firms";
import { CoastalARow } from "./Rows/CoastalARow";
import {
  HelpOptionReasonCode,
  PropertyAttributeSource,
  useGetGetHelpDataQuery,
} from "../../../../../generated/graphql";
import { DatumRow } from "./Rows/DatumRow";

import { PROPERTY_ATTRIBUTE_NAMES } from "common/constants";
import { RasterRow } from "../../Common/RasterRow";
import { BlueAnchor, WarningIconWrapper } from "./__styles__/FIRMTable";
import {
  Table,
  TableBody,
  TableHeader,
} from "../../../../Common/__styles__/StripedTable";
import { CustomMapRow } from "../../Common/CustomMapRow";
import { MSCLinkRow } from "./Rows/MSCLinkRow";
import { BFERow } from "./Rows/BFERow";
import { PropertyOverviewContext } from "../../PropertyOverviewContext";
import { PropertyOverviewTooltip } from "../../Common/PropertyOverviewTooltip";

export const FIRMTableTooltip = ({
  tooltipMarkdown,
  isGuestOnly = true,
}: {
  tooltipMarkdown?: Maybe<string>;
  isGuestOnly?: boolean;
}) => {
  const { activeFirmIds } = useContext(PropertyOverviewContext);
  if (activeFirmIds.length > 1) {
    return <></>;
  }

  return (
    <PropertyOverviewTooltip
      tooltipMarkdown={tooltipMarkdown}
      isGuestOnly={isGuestOnly}
    />
  );
};

export interface FIRMTableProps {
  account: Account;
  property?: Maybe<Property>;
  Footer?: Maybe<React.FC<{ Blank: ComponentType }>>;
  Header?: Maybe<ComponentType>;
  showTitle: boolean;
  onFIRMEdit: (args: { firm: FIRMWithWarnings; mode: EditableMode }) => void;
  onFIRMClick?: (firmId: string) => void;
  size?: RenderMode;
  isComparisonModal?: boolean;
  section: AuthAccount["propertyInformationSections"][number];
}

function FIRMTable({
  property,
  Footer,
  Header,
  showTitle,
  onFIRMEdit,
  onFIRMClick,
  size = "narrow",
  isComparisonModal = false,
  section,
}: FIRMTableProps) {
  const { authorized } = useContext(AuthContext);
  const { activeFirmIds, firms, initialFIRMDate, preOrPostFIRM } = useContext(
    PropertyOverviewContext
  );

  const firmsForTable = firms.filter(firm => activeFirmIds.includes(firm.id));

  const editable = editableInformationForFIRMs(firmsForTable);

  const canUpdateFIRMInfo = authorized({
    resource: RESOURCE_NAME.FIRM,
    permission: "update",
  });

  const { data: helpData } = useGetGetHelpDataQuery();

  const editWrapper = ({ mode }: { mode: EditableMode }) => {
    return ({ firmId, children }: { firmId: string; children?: ReactNode }) => {
      const attributeIsEditable = editable[firmId]?.[mode];

      if (attributeIsEditable && property && canUpdateFIRMInfo) {
        const firm = find(firmsForTable, firm => firm.id === firmId)!;

        return (
          <a
            data-testid={`edit-${mode}`}
            onClick={() => onFIRMEdit({ firm, mode })}
          >
            {children}
          </a>
        );
      } else {
        return <>{children}</>;
      }
    };
  };

  const wrapFloodZone = editWrapper({ mode: "floodzone" });
  const wrapFloodway = editWrapper({ mode: "floodway" });
  const wrapPanel = editWrapper({ mode: "panel" });

  const wrapHeader = ({
    firmId,
    children,
  }: {
    firmId: string;
    children?: ReactNode;
  }) => {
    const showWarning = !!firmsForTable.find(firm => firm.id === firmId)
      ?.warnings.length;

    const childrenWrapper = onFIRMClick ? (
      <BlueAnchor onClick={() => onFIRMClick(firmId)}>{children}</BlueAnchor>
    ) : (
      children
    );

    return (
      <span>
        {showWarning && <WarningIconWrapper src={WarningIcon} />}
        {childrenWrapper}
      </span>
    );
  };

  const hasRequestBFEHelpOption =
    helpData?.account?.publicPortal.accountInformationRequestHelpOptions.some(
      option => option.reasonCodes?.includes(HelpOptionReasonCode.REQUEST_BFE)
    );

  const valueForFIRMName = (firm: FIRMWithWarnings) => {
    return isEmptyFIRM(firm) ? "" : firm.name;
  };

  const getElementForAttribute = (
    propertyAttributeName: PROPERTY_ATTRIBUTE_NAMES
  ): ((
    _: Omit<FloodInfoBasePropertyAttribute, "tooltip"> & {
      tooltip?: JSX.Element;
      size: RenderMode;
    }
  ) => JSX.Element) => {
    switch (propertyAttributeName) {
      case PROPERTY_ATTRIBUTE_NAMES.FLOODZONE:
        return props => (
          <FIRMInfoRow {...props} property="floodzone" wrap={wrapFloodZone} />
        );
        break;

      case PROPERTY_ATTRIBUTE_NAMES.COASTAL_A:
        return props => <CoastalARow {...props} onFIRMEdit={onFIRMEdit} />;

      case PROPERTY_ATTRIBUTE_NAMES.FLOODWAY:
        return props => (
          <FIRMInfoRow
            {...props}
            property="floodway"
            format={formatNullableBoolean}
            wrap={wrapFloodway}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.CBRS:
        return props => (
          <FIRMInfoRow
            {...props}
            property="cbrs"
            format={formatNullableBoolean}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.OPA:
        return props => (
          <FIRMInfoRow
            {...props}
            property="opa"
            format={formatNullableBoolean}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.BFE:
        return props => (
          <BFERow
            canUpdateFIRMInfo={canUpdateFIRMInfo}
            onFIRMEdit={onFIRMEdit}
            property={property}
            hasRequestBFEHelpOption={hasRequestBFEHelpOption}
            {...props}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.DEPTH:
        return props => (
          <FIRMInfoRow
            {...props}
            property="stringDepth"
            format={formatFeet}
            wrap={wrapFloodZone}
            skipIfBlank
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.DFE:
        return props => (
          <DesignFloodElevationRow
            property={property}
            hasRequestBFEHelpOption={hasRequestBFEHelpOption}
            {...props}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.DATUM:
        return props => <DatumRow {...props} onFIRMEdit={onFIRMEdit} />;

      case PROPERTY_ATTRIBUTE_NAMES.EFFECTIVE_DATE:
        return props => (
          <FIRMInfoRow
            {...props}
            property="effectiveDate"
            format={formatDate}
            wrap={wrapPanel}
          />
        );

      case PROPERTY_ATTRIBUTE_NAMES.PANEL_NUMBER:
        return props => (
          <FIRMInfoRow {...props} property="panelNumber" wrap={wrapPanel} />
        );

      case PROPERTY_ATTRIBUTE_NAMES.MSC_LINK:
        return props => <MSCLinkRow {...props} />;

      case PROPERTY_ATTRIBUTE_NAMES.INITIAL_DATE:
        if (isComparisonModal || !initialFIRMDate) {
          return () => <></>;
        } else {
          return props => (
            <FIRMInfoRow
              {...props}
              property="initialFIRMDate"
              valueForFIRM={() => initialFIRMDate}
            />
          );
        }

      case PROPERTY_ATTRIBUTE_NAMES.PRE_POST_FIRM:
        if (isComparisonModal || !preOrPostFIRM) {
          return () => <></>;
        } else {
          return props => (
            <FIRMInfoRow
              {...props}
              property="preOrPostFIRM"
              valueForFIRM={() => preOrPostFIRM}
            />
          );
        }

      default:
        return () => <></>;
    }
  };

  return (
    <Table role="table" spacing={isComparisonModal ? "positive" : "negative"}>
      <TableHeader>
        {Header ? (
          <Header />
        ) : (
          <FIRMInfoRow
            label={showTitle ? section.label : ""}
            wrap={wrapHeader}
            valueForFIRM={valueForFIRMName}
            property="name"
            format={(name: string) => startCase(name)}
            isPublic={true}
            header={showTitle}
            size={size}
          />
        )}
      </TableHeader>
      <TableBody>
        {section.accountPropertyAttributes!.map(attribute => {
          if (!attribute.sourceId) {
            const attributeName = attribute.name as PROPERTY_ATTRIBUTE_NAMES;
            const Element = getElementForAttribute(attributeName);
            return (
              <Fragment key={attribute.id}>
                <Element
                  {...attribute}
                  size={size}
                  tooltip={FIRMTableTooltip({
                    tooltipMarkdown: attribute.tooltip,
                  })}
                />
              </Fragment>
            );
          }

          if (isComparisonModal) {
            return <></>;
          }

          if (attribute.source === PropertyAttributeSource.CUSTOM_MAP) {
            return (
              <CustomMapRow
                accountPropertyAttribute={attribute}
                key={attribute.id}
                size={size}
              />
            );
          }

          return (
            <RasterRow
              accountPropertyAttribute={attribute}
              key={attribute.id}
              property={property}
              size={size}
            />
          );
        })}
        {!!Footer && <Footer Blank={Blank} />}
      </TableBody>
    </Table>
  );
}

export default FIRMTable;
