import React from "react";
import {
  Disclaimer,
  DisclaimerContainer,
  IconWrapper,
  DisclaimerHeader as DisclaimerHeaderStyle,
} from "./__styles__/Disclaimer";
import { Icon } from "./Icons/LucideIcons";

export const DisclaimerHeader = (
  <DisclaimerHeaderStyle>Disclaimer:</DisclaimerHeaderStyle>
);

export default ({ message, testId }: { message: string; testId?: string }) => {
  return (
    <DisclaimerContainer data-testid={testId}>
      <IconWrapper>
        <Icon
          iconName={"info"}
          color="contentSecondary"
          strokeWidth={"2"}
          size={15}
        />
      </IconWrapper>
      <Disclaimer markdown={message} />
    </DisclaimerContainer>
  );
};
