import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Layout from "../Common/Layout";

import { DataImports } from "./DataImports";
import Team from "./Team";
import UserSettings from "./User";

import { AuthContext } from "../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { CurrentAdmin } from "../Authorization/types";
import { InsufficientPermissions } from "../Common/InsufficientPermissions";
import { Account } from "./Account";

import { PermissionsWrapper } from "./__styles__/Settings";

const InsufficientPermissionsWrapper = () => {
  return (
    <PermissionsWrapper>
      <InsufficientPermissions />
    </PermissionsWrapper>
  );
};

const userTabs = ({
  canViewTeamSettings,
  canViewDataImportsTab,
  admin,
}: {
  canViewTeamSettings: boolean;
  canViewDataImportsTab: boolean;
  admin: Maybe<CurrentAdmin>;
}) => {
  const tabs: Array<{ name: string; path: string }> = [];

  if (!admin) {
    tabs.push({ name: "User", path: "/settings/user" });
  }

  tabs.push({ name: "Account", path: "/settings/account" });

  if (canViewDataImportsTab) {
    tabs.push({
      name: "Data imports",
      path: "/settings/data-imports",
    });
  }

  if (canViewTeamSettings) {
    tabs.push({
      name: "Team",
      path: "/settings/team",
    });
  }

  return tabs;
};

export default () => {
  const { admin, authorized } = useContext(AuthContext);
  const canViewTeamSettings = authorized({
    resource: RESOURCE_NAME.USER,
    permission: "list",
  });
  const canViewDataImportsTab = authorized({
    resource: RESOURCE_NAME.DATA_IMPORTS,
    permission: "view",
  });

  return (
    <Layout
      title="Settings"
      tabs={userTabs({
        canViewTeamSettings,
        canViewDataImportsTab,
        admin,
      })}
    >
      <Switch>
        {!admin && <Route path="/settings/user" component={UserSettings} />}
        <Route path="/settings/account" component={Account} />
        <Route
          path="/settings/data-imports"
          component={
            canViewDataImportsTab ? DataImports : InsufficientPermissionsWrapper
          }
        />
        <Route
          path="/settings/team"
          component={
            canViewTeamSettings ? Team : InsufficientPermissionsWrapper
          }
        />
        <Redirect to={admin ? "/settings/account" : "/settings/user"} />
      </Switch>
    </Layout>
  );
};
