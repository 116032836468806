import React from "react";
import { MIME_TYPE } from "common/constants";
import Icon, { Icons } from "../../Common/Icons";

export interface DocumentUploadIconProps {
  documentUploadMimeType: MIME_TYPE;
}

export default ({ documentUploadMimeType }: DocumentUploadIconProps) => {
  let icon: Icon;

  switch (documentUploadMimeType) {
    case MIME_TYPE.JPEG:
    case MIME_TYPE.JPG:
    case MIME_TYPE.PNG:
      icon = Icons.IMAGE;
      break;
    case MIME_TYPE.PDF:
    default:
      icon = Icons.PDF;
      break;
  }

  return <Icon icon={icon} />;
};
