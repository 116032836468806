import React, { useContext } from "react";
import { AddressPanelTabProps } from "..";
import {
  SubmissionCategory,
  SubmissionIntakeSource,
  useGetSubmissionsForPropertyQuery,
} from "../../../generated/graphql";
import Divider from "../../Common/Divider";
import { EmptyState } from "../../Common/EmptyState";
import MissingProperty from "../MissingProperty";
import { Layout } from "../Records";
import ListItem from "./ListItem";
import { CreateSubmissionButton } from "../../Common/CreateSubmissionButton";
import { canCreateInternalSubmission } from "common/authorization/customPolicies";
import { AuthContext } from "../../Authorization/AuthContext";
import { AddressPanelContext } from "../AddressPanelContext";

export default ({ address, latitude, longitude }: AddressPanelTabProps) => {
  const { user, admin } = useContext(AuthContext);
  const { property, loading: loadingPropertyId } =
    useContext(AddressPanelContext);
  const propertyId = property?.id;
  const variables = {
    propertyId,
    category: SubmissionCategory.PERMITTING,
  };
  const { data, loading, error, refetch } = useGetSubmissionsForPropertyQuery({
    variables,
    skip: loadingPropertyId || !propertyId,
    fetchPolicy: "cache-and-network",
  });

  const title = "Permits";
  if (loading || loadingPropertyId) {
    return (
      <Layout title={title}>
        <em>Loading...</em>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title={title}>
        <span>Error loading permits</span>
      </Layout>
    );
  }

  if (!propertyId || !data?.getProperty) {
    return (
      <MissingProperty
        title="Permits are not available for this property"
        preamble="Permits are unfortunately not available for properties that do not have a street address"
        address={address}
        latitude={latitude}
        longitude={longitude}
      />
    );
  }

  const currentUser = user || admin;
  const canCreatePermittingSubmission = canCreateInternalSubmission({
    role: currentUser?.role.name,
  });

  const actionButton = (
    <CreateSubmissionButton
      disabled={!canCreatePermittingSubmission}
      address={address}
      latitude={latitude}
      longitude={longitude}
      propertyId={propertyId!}
      submissionText="inspection"
      submissionTypeFilter={submissionType =>
        submissionType.category === SubmissionCategory.PERMITTING &&
        submissionType.intakeSource === SubmissionIntakeSource.INTERNAL
      }
    />
  );

  const submissions = data.getProperty.submissions;
  if (!submissions.length) {
    return (
      <React.Fragment>
        <Layout title={title} actionButton={actionButton}>
          <EmptyState message="There are no permits for this property yet" />
        </Layout>
        <Divider margins="24px 0 0 0" />
      </React.Fragment>
    );
  }

  return (
    <Layout title={title} actionButton={actionButton}>
      {submissions.map(submission => (
        <ListItem
          key={submission.id}
          submission={submission}
          onUpdate={refetch}
        />
      ))}
    </Layout>
  );
};
