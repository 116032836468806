import React, { useContext, useEffect, useState } from "react";

import { FieldProps } from "@rjsf/utils";
import { Input } from "../__styles__/Inputs";
import { ComplexRadio, Number } from "../../Inputs";
import {
  Multiplier,
  NumericInputs,
  SummaryBox,
  SummaryRow,
  SummaryText,
} from "./__styles__/PropertyMarketValueField";
import Wrapper from "../../Inputs/Wrapper";
import {
  get,
  identity,
  isNil,
  keys,
  merge,
  pick,
  times,
  values,
  zipObject,
} from "lodash";
import { formatCurrency } from "common/utils/strings";
import { SubmissionFormContext } from "../Form";
import { isNotNil } from "common/utils/tools";
import { calculateFullAssessedValue } from "common/utils/improvements";
import {
  PropertyMarketValueFormData as FormData,
  PropertyMarketValueSchema as FullSchema,
  extractErrorsFromErrorSchema,
} from "common-client/utils/submissions";
import { useDefaultedValues } from "../utils";

const PropertyMarketValue = (
  props: FieldProps<FormData, FullSchema["schema"], FullSchema["uiSchema"]> & {
    isSubmissionBuilder?: boolean;
  }
) => {
  const { onChange, formData, uiSchema, schema, errorSchema } = props;
  const { defaultValues } = useContext(SubmissionFormContext);
  // filter out automatedAssessedValue if not all fields are defaulted,
  // since we can't compute a value then
  const options = schema.oneOf.filter((option, index) => {
    if (option.name === "automatedAssessedValue") {
      return values(uiSchema?.oneOf[index]).every(value => {
        const defaultPath = value["ui:defaultValuePath"];
        return !defaultPath || !isNil(get(defaultValues, defaultPath));
      });
    } else {
      return true;
    }
  });

  const initialOption = options[0]!.name;

  const [initialFormData] = useState(formData ?? { type: initialOption });

  const setSelectedOption = (newSelectedOption: FormData["type"]) => {
    // if the user is editing a form, we need to make sure we don't *lose*
    // the data they had if they switch between the options
    const initialData =
      initialFormData.type === newSelectedOption ? initialFormData : {};

    // force value to `null` if it's not in the initial data so
    // validations apply if you switch between options *before* entering data
    onChange({ value: null, ...initialData, type: newSelectedOption });
  };

  const errors = extractErrorsFromErrorSchema<FormData>(errorSchema);

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Wrapper
        name={props.schema.title}
        required={props.required}
        label={props.schema.title}
        compactLabel
        description={props.description}
      >
        {options.map((option, index) => {
          const checked = (formData?.type || initialOption) === option.name;
          const Component = OptionToComponent[
            option.name
          ] as unknown as React.FC<
            SubcomponentProps<
              | "automatedAssessedValue"
              | "otherValueEstimate"
              | "squareFootageEstimate"
            >
          >;

          return (
            <ComplexRadio
              key={option.name}
              value={option.name}
              name={option.name}
              label={option.title}
              checked={checked}
              onChange={setSelectedOption}
              description={option.description}
            >
              {checked && (
                <Component
                  {...option}
                  onChange={(newValue: Partial<FormData>) => {
                    const propertiesOnOption = keys(option.properties);

                    // required to mark all fields on the option as dirty
                    // for validation purposes, even if you only edited
                    // one field
                    const emptyDefaults = zipObject(
                      propertiesOnOption,
                      times(propertiesOnOption.length, () => null)
                    );
                    const existingValues = pick(formData, propertiesOnOption);

                    const finalValue = merge(
                      {},
                      emptyDefaults,
                      existingValues,
                      newValue,
                      {
                        type: option.name,
                      }
                    );

                    onChange(finalValue);
                  }}
                  formData={formData ?? {}}
                  uiSchema={uiSchema!.oneOf[index]}
                  errors={errors}
                />
              )}
            </ComplexRadio>
          );
        })}
      </Wrapper>
    </Input>
  );
};
type SubcomponentProps<T extends FormData["type"]> = Extract<
  FullSchema["schema"]["oneOf"][number],
  { name: T }
> & {
  onChange: (value: Partial<Extract<FormData, { type: T }>>) => void;
  formData: Omit<Extract<FormData, { type: T }>, "type">;
  uiSchema: Omit<
    Extract<FullSchema["uiSchema"]["oneOf"][number], { _name: T }>,
    "_name"
  >;
  errors?: {
    [key in keyof Omit<Extract<FormData, { type: T }>, "type">]?: Maybe<string>;
  };
};

const SquareFootageEstimate = (
  props: SubcomponentProps<"squareFootageEstimate">
) => {
  const { formData, onChange, properties, errors } = props;
  const { sqftOfProperty, costPerSqft, adjustmentRatio } = properties;

  useEffect(() => {
    if (
      isNotNil(formData.sqftOfProperty) &&
      isNotNil(formData.costPerSqft) &&
      isNotNil(formData.adjustmentRatio)
    ) {
      onChange({
        value:
          formData.sqftOfProperty *
          formData.costPerSqft *
          formData.adjustmentRatio,
      });
    } else {
      onChange({ value: null });
    }
  }, [formData.adjustmentRatio, formData.costPerSqft, formData.sqftOfProperty]);

  const fields = ["sqftOfProperty", "costPerSqft", "adjustmentRatio"];
  const hasError = values(pick(errors, fields)).some(identity);

  return (
    <div>
      <NumericInputs>
        <Number
          compactLabel
          title={sqftOfProperty.title}
          value={formData.sqftOfProperty ?? null}
          name={"sqftOfProperty"}
          label={sqftOfProperty.title}
          onChange={sqftOfProperty => onChange({ sqftOfProperty })}
          error={errors?.sqftOfProperty}
        />
        <Multiplier hasError={hasError}>X</Multiplier>
        <Number
          compactLabel
          title={costPerSqft.title}
          value={formData.costPerSqft ?? null}
          name={"costPerSqft"}
          label={costPerSqft.title}
          onChange={costPerSqft => onChange({ costPerSqft })}
          error={errors?.costPerSqft}
        />
        <Multiplier hasError={hasError}>X</Multiplier>
        <Number
          compactLabel
          title={adjustmentRatio.title}
          value={formData.adjustmentRatio ?? null}
          name={"adjustmentRatio"}
          label={adjustmentRatio.title}
          onChange={adjustmentRatio => onChange({ adjustmentRatio })}
          error={errors?.adjustmentRatio}
        />
      </NumericInputs>
      <SummaryBox>
        <SummaryRow>
          <SummaryText>Estimated Value</SummaryText>
          <SummaryText>
            {isNotNil(formData.value) ? formatCurrency(formData.value) : "--"}
          </SummaryText>
        </SummaryRow>
      </SummaryBox>
    </div>
  );
};

const OtherValueEstimate = (
  props: SubcomponentProps<"squareFootageEstimate">
) => {
  const { onChange, formData, errors } = props;

  return (
    <NumericInputs>
      <Number
        compactLabel
        value={formData.value ?? null}
        name={"otherValueEstimate"}
        onChange={value => onChange({ value })}
        prefix={"$"}
        error={errors?.value}
      />
    </NumericInputs>
  );
};

const AutomatedAssessedValue = (
  props: SubcomponentProps<"automatedAssessedValue">
) => {
  const { onChange, formData, uiSchema } = props;
  const { improvementValue, adjustmentRatio } = useDefaultedValues({
    uiSchema: uiSchema,
    values: formData,
    onChange,
    skipIfEditing: false,
  });

  useEffect(() => {
    if (!isNil(improvementValue) && !isNil(adjustmentRatio)) {
      onChange({
        value: calculateFullAssessedValue(improvementValue, adjustmentRatio),
      });
    }
  }, [improvementValue, adjustmentRatio]);

  return (
    <SummaryBox>
      <SummaryRow>
        <SummaryText bold={false}>Improvement Value</SummaryText>
        <SummaryText bold={false}>
          {formatCurrency(improvementValue)}
        </SummaryText>
      </SummaryRow>
      <SummaryRow underlined>
        <SummaryText bold={false}>Adjustment Ratio</SummaryText>
        <SummaryText bold={false}>{adjustmentRatio}</SummaryText>
      </SummaryRow>
      <SummaryRow>
        <SummaryText>Assessed Value</SummaryText>
        <SummaryText>{formatCurrency(formData.value)}</SummaryText>
      </SummaryRow>
    </SummaryBox>
  );
};

const OptionToComponent = {
  squareFootageEstimate: SquareFootageEstimate,
  automatedAssessedValue: AutomatedAssessedValue,
  otherValueEstimate: OtherValueEstimate,
};

// get around some weird TS issue with `FieldProps`
export const PropertyMarketValueInput = (props: FieldProps) => {
  return <PropertyMarketValue {...(props as any)} />;
};
