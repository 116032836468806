import React from "react";
import {
  GetAccountDocumentTemplatesIdAndNameQuery,
  Property,
  useGenerateDocumentFromTemplateMutation,
} from "../../../generated/graphql";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { Radio } from "../../Inputs";
import { Button } from "../../Common/Button";

import { Controller, useForm } from "react-hook-form";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { track } from "../../../utils/tracking";
import { buildLink } from "common/routing";
import { useHistory } from "react-router-dom";

type Account = NonNullable<
  GetAccountDocumentTemplatesIdAndNameQuery["account"]
>;
type DocumentTemplates = Account["documentTemplates"];

export interface GenerateDocumentForm {
  documentTemplateId: string;
}

export interface GenerateDocumentFormProps {
  property: Pick<Property, "id" | "fullAddress">;
  documentTemplates: DocumentTemplates;
  updateMap: () => void;
  closeModal: () => void;
}

export const GenerateDocumentForm = ({
  property,
  documentTemplates,
  updateMap,
  closeModal,
}: GenerateDocumentFormProps) => {
  const history = useHistory();
  const { addFailureToast, addSuccessToast } = useStatusToasts();
  const { control, setValue, handleSubmit, watch } =
    useForm<GenerateDocumentForm>();

  const documentTemplateId = watch("documentTemplateId");

  const propertyId = property.id;
  const fullAddress = property.fullAddress;

  const options = documentTemplates.map(documentTemplate => ({
    text: documentTemplate.name,
    value: documentTemplate.id,
  }));

  const [generateDocument, { loading: loadingGenerateDocument }] =
    useGenerateDocumentFromTemplateMutation({
      onCompleted: data => {
        closeModal();
        updateMap();
        addSuccessToast("Your document was successfully generated");
        const documentTemplate = documentTemplates.find(
          documentTemplate => documentTemplate.id === documentTemplateId
        );
        track("Generated document from template", {
          accountDocumentTypeId: documentTemplate?.accountDocumentType.id,
          accountDocumentTypeName: documentTemplate?.accountDocumentType.name,
          propertyId,
          propertyFullAddress: fullAddress,
        });
        history.push(
          buildLink("documentUploadDetail", {
            id: data.generateDocumentFromTemplate?.id,
          })
        );
      },
      onError: () => {
        const message =
          "There was an issue generating your document. Please try again. If the problem persists, please email us at support@withforerunner.com";
        addFailureToast(message);
      },
    });

  const onSubmit = async (data: GenerateDocumentForm) => {
    await generateDocument({
      variables: {
        templateId: data.documentTemplateId,
        propertyId,
      },
    });
  };

  return (
    <Container>
      <HeaderSection>
        <h1 style={{ marginBottom: "8px" }}>Select document template</h1>
        <h2>
          Select the document template that you would like to create for{" "}
          {fullAddress}
        </h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <Controller
            control={control}
            name="documentTemplateId"
            render={({ field, fieldState }) => {
              return (
                <Radio
                  value={field.value}
                  options={options}
                  name="documentTemplates"
                  required={true}
                  disabled={false}
                  onChange={value => setValue("documentTemplateId", value!)}
                  error={fieldState.error?.message}
                  fontSize="normal"
                  fontColor="black"
                />
              );
            }}
          />
        </ContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button
            styleVariant="secondary"
            size="medium"
            disabled={loadingGenerateDocument}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            size="medium"
            data-testid="generate"
            disabled={!documentTemplateId || loadingGenerateDocument}
            loading={loadingGenerateDocument}
            onClick={handleSubmit(onSubmit)}
          >
            Create
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
