import React from "react";
import {
  Title,
  SummaryRow,
  SummaryRowLabel,
  SummaryRowValue,
  SummaryTable,
  PhotoGallery,
  Container,
  TooltipContent,
  CellWithTooltip,
} from "./__styles__/SDESummary";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../Common/__styles__/StripedTable";
import { SummaryProps } from ".";
import { formatCurrency, formatPercent } from "common/utils/strings";
import Disclaimer from "../../Common/Disclaimer";
import { isNotNil } from "common/utils/tools";
import { buildLink } from "common/routing";
import { InformationalTooltip } from "../../Common/Tooltip";

const isSDESummary = (
  summary: SummaryProps["submission"]["summaries"][number]
) => {
  return summary.__typename === "SDESubmissionSummary";
};

export const SDESummary: React.FC<SummaryProps> = ({ submission }) => {
  const { formData, documentUploads, summaries } = submission;
  const summary = summaries.find(isSDESummary);

  if (!summary) {
    throw new Error(
      "SDESummary not found in submission's summaries, which indicates that this summary page was rendered incorrectly."
    );
  }

  const images =
    formData.images
      ?.map(image => {
        return documentUploads.find(doc => doc.id === image.id);
      })
      .filter(isNotNil) || [];

  const {
    totalDamages,
    totalCosts,
    elementBreakdowns,
    depreciationPercentage,
    depreciationValue,
    propertyMarketValue,
    estimatedRepairReconstructionPercentage,
  } = summary;

  return (
    <Container>
      <div>
        <Title>Estimated damage summary</Title>
        <SummaryTable>
          <SummaryRow>
            <SummaryRowLabel>
              Estimated repair / reconstruction percentage (%)
              <InformationalTooltip
                popoverContent={
                  <TooltipContent>
                    Estimated computed damages as a percentage of the computed
                    actual cash value of the structure
                  </TooltipContent>
                }
                place={"top"}
              />
            </SummaryRowLabel>
            <SummaryRowValue>
              {formatPercent(
                estimatedRepairReconstructionPercentage.toFixed(2)
              )}
            </SummaryRowValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryRowLabel>
              Base cost
              <InformationalTooltip
                popoverContent={
                  <TooltipContent>
                    Value of structure defined in inspection form
                  </TooltipContent>
                }
                place={"top"}
              />
            </SummaryRowLabel>
            <SummaryRowValue>
              {formatCurrency(propertyMarketValue)}
            </SummaryRowValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryRowLabel>
              Estimated depreciation (%)
              <InformationalTooltip
                popoverContent={
                  <TooltipContent>
                    Based on depreciation determination identified in inspection
                    form
                  </TooltipContent>
                }
                place={"top"}
              />
            </SummaryRowLabel>
            <SummaryRowValue>{depreciationPercentage}%</SummaryRowValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryRowLabel>
              Computed actual cash value
              <InformationalTooltip
                popoverContent={
                  <TooltipContent>
                    Value of the structure based on base cost and estimated
                    depreciation
                  </TooltipContent>
                }
                place={"top"}
              />
            </SummaryRowLabel>
            <SummaryRowValue>
              {formatCurrency(depreciationValue)}
            </SummaryRowValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryRowLabel>
              Estimated computed damages
              <InformationalTooltip
                popoverContent={
                  <TooltipContent>
                    Total sum of all element damage values
                  </TooltipContent>
                }
                place={"top"}
              />
            </SummaryRowLabel>
            <SummaryRowValue>{formatCurrency(totalDamages)}</SummaryRowValue>
          </SummaryRow>
        </SummaryTable>
      </div>
      <div>
        <Title>Estimated computed damages breakdown</Title>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell bold>Element</TableCell>
              <TableCell bold>
                <CellWithTooltip>
                  <span>Structure %</span>
                  <InformationalTooltip
                    popoverContent={
                      <TooltipContent>
                        Building element value as a percentage of total
                        appraised value, based on structure information provided
                      </TooltipContent>
                    }
                    place={"top"}
                  />
                </CellWithTooltip>
              </TableCell>
              <TableCell bold>Element Cost</TableCell>
              <TableCell bold>Damage</TableCell>
              <TableCell bold>Damage Value</TableCell>
            </TableRow>
            {elementBreakdowns.map(row => {
              return (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.structurePercentage}</TableCell>
                  <TableCell>{formatCurrency(row.cost)}</TableCell>
                  <TableCell>{formatPercent(row.damagePercentage)}</TableCell>
                  <TableCell>{formatCurrency(row.damageValue)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell bold>Totals</TableCell>
              <TableCell></TableCell>
              <TableCell bold>{formatCurrency(totalCosts)}</TableCell>
              <TableCell></TableCell>
              <TableCell bold>{formatCurrency(totalDamages)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Disclaimer message={DISCLAIMER_TEXT} />
      <Photos images={images} />
    </Container>
  );
};

const Photos = ({
  images,
}: {
  images: Array<{ id: string; originalFilename: string }>;
}) => {
  if (!images.length) return null;

  return (
    <div>
      <Title>Photos</Title>
      <PhotoGallery>
        {images.map(image => {
          return (
            <img
              key={image.id}
              src={buildLink("documentUploadFile", { id: image.id })}
              alt={image.originalFilename}
            />
          );
        })}
      </PhotoGallery>
    </div>
  );
};

const DISCLAIMER_TEXT =
  "Disclaimer: Forerunner's substantial damage estimate summaries are designed to provide preliminary estimates based on the information provided by the user and Forerunner's embedded algorithms. These estimates are intended for general guidance purposes only and should not be construed as definitive or accurate assessments of actual damage costs.";
