import { isNil, omit } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  useGetResetMfaUserOptionsQuery,
  useRequestMfaResetMutation,
  UserLoginErrorCode,
} from "../../generated/graphql";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { Row } from "../Common/__styles__/Layout";
import {
  ButtonRow,
  Title,
} from "../Common/__styles__/FormWithFullPageBackground";
import { Button } from "../Common/Button";
import { Select } from "../Inputs/react-hook-form";
import { ErrorMessage } from "./ErrorMessage";
import { LoginFormStructure } from "./LoginPage";
import { MFAFlowAction } from "common-client/utils/useMFAFlow";
import { SecondaryAction } from "./__styles__/LoginPage";

export interface SelectRequestUserProps {
  dispatch: React.Dispatch<MFAFlowAction>;
  error?: Maybe<{ message: string; code?: UserLoginErrorCode }>;
}
export const SelectRequestUser = ({
  dispatch,
  error,
}: SelectRequestUserProps) => {
  const { addFailureToast } = useStatusToasts();
  const { control, getValues, handleSubmit, watch } =
    useFormContext<LoginFormStructure>();

  const [email, password] = getValues(["email", "password"]);
  const { data } = useGetResetMfaUserOptionsQuery({
    variables: {
      data: { email: email!, password: password! },
    },
  });

  const [requestMfaReset, { loading: resetRequestLoading }] =
    useRequestMfaResetMutation({
      onCompleted: data =>
        dispatch({ type: "requestReset", data: data.requestMFAReset }),
      onError: () =>
        addFailureToast(
          `There was an issue requesting an MFA reset. Please try again. If the problem persists, please email us at support@withforerunner.com`
        ),
    });

  if (data?.resetMFAUserOptions.error) {
    dispatch({
      type: "requestReset",
      data: omit(data.resetMFAUserOptions, "resetUserOptions"),
    });
  }

  if (data && !data.resetMFAUserOptions.resetUserOptions?.length) {
    dispatch({ type: "requestReset" });
  }

  const requestedUserOptions =
    data?.resetMFAUserOptions.resetUserOptions?.map(user => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    })) ?? [];

  const handleRequestReset = async (loginData: LoginFormStructure) => {
    await requestMfaReset({
      variables: {
        data: {
          email: loginData.email!,
          password: loginData.password!,
          requestedUserId: loginData.requestedUserId!,
        },
      },
    });
  };

  const returnToCode = () => {
    dispatch({
      type: "sendCode",
      data: omit(data?.resetMFAUserOptions, "resetUserOptions"),
    });
  };

  const requestedUserId = watch("requestedUserId");
  const disabled = isNil(requestedUserId);

  return (
    <>
      <Title>Request an MFA reset</Title>
      {error && <ErrorMessage error={error} />}
      <div>
        <Row>
          Please select the name of the manager on your account you would like
          to request a reset from
        </Row>
        <Row>
          <Select
            control={control}
            name="requestedUserId"
            options={requestedUserOptions}
          />
        </Row>
        <SecondaryAction onClick={returnToCode}>
          Return to authentication code entry
        </SecondaryAction>
      </div>
      <ButtonRow>
        <Button
          onClick={handleSubmit(handleRequestReset)}
          disabled={disabled || resetRequestLoading}
          size="medium"
          styleVariant="primary"
        >
          Submit
        </Button>
      </ButtonRow>
    </>
  );
};
