import React, { createContext, ReactNode, useContext, useState } from "react";
import {
  FIRMsWithWarnings,
  FIRMWithWarnings,
  getWarningsForFirms,
} from "common-client/utils/firmInfoWarnings";
import {
  createEmptyFIRM,
  generateAllFIRMs,
  editableInformationForFIRMs as getEditableInformationForFIRMs,
  isEmptyFIRM,
  shouldShowCoastalA,
} from "common-client/utils/firms";
import { AddressPanelContext } from "../AddressPanelContext";
import {
  CustomMapIntersection,
  FirmJurisdiction,
  GeometryType,
  GetFloodInfoQuery,
  useGetFloodInfoQuery,
} from "../../../generated/graphql";
import { every } from "lodash";
import { AuthContext } from "../../Authorization/AuthContext";
import { formatDateString } from "common/utils/dates";
import { arrayHasAtLeastOneItem } from "common/utils/tools";

/** Dummy ID for the case where an account has no FIRMs */
export const NO_FIRM_ID = "no-firm";

export type PropertyOverviewContextType = {
  firms: Array<FIRMWithWarnings & { digitized: boolean }>;
  activeFirmIds: Array<string>;
  setActiveFirmIds: (firmIds: Array<string>) => void;
  loading: boolean;
  getPopulatedFirms: () => FIRMsWithWarnings;
  customMapsInfo: Array<CustomMapIntersection>;
  queryGeometryType: GeometryType;
  initialFIRMDate?: Maybe<string>;
  preOrPostFIRM?: Maybe<string>;
  longitude?: Maybe<number>;
  latitude?: Maybe<number>;
};

export const PropertyOverviewContext =
  createContext<PropertyOverviewContextType>({
    firms: [],
    activeFirmIds: [],
    setActiveFirmIds: () => {},
    loading: false,
    getPopulatedFirms: () => [],
    customMapsInfo: [],
    queryGeometryType: GeometryType.POINT,
    initialFIRMDate: "",
  });

export const PropertyOverviewContextProvider = ({
  children,
  longitude,
  latitude,
}: {
  children: ReactNode;
  longitude: number;
  latitude: number;
}) => {
  const { property } = useContext(AddressPanelContext);
  const { account } = useContext(AuthContext);
  const [activeFirmIds, setActiveFirmIds] = useState<Array<string>>([]);
  const [firms, setFirms] = useState<
    Array<FIRMWithWarnings & { digitized: boolean }>
  >([]);
  const [customMapsInfo, setCustomMapsInfo] = useState<
    Array<CustomMapIntersection>
  >([]);
  const [firmInfo, setFirmInfo] = useState<
    Pick<
      GetFloodInfoQuery["FIRMInfo"],
      "queryGeometryType" | "initialFIRMDate" | "preOrPostFIRM"
    >
  >({
    queryGeometryType: GeometryType.POINT,
    initialFIRMDate: "",
  });
  const propertyId = property?.id;
  const point = {
    crs: { type: "name", properties: { name: "EPSG:4326" } },
    type: "Point",
    coordinates: [longitude, latitude],
  };
  const { loading } = useGetFloodInfoQuery({
    // we use no-cache here because graphql will cache firms
    // by id, but because we return firms with the same id
    // with *wildly* differing attributes for the firm itself
    // (since those attributes are property-dependent), we
    // can't rely on cached data at a property-level
    fetchPolicy: "no-cache",
    variables: { point, propertyId },
    onCompleted: data => {
      const propertyFirms = data.FIRMInfo.firms;
      // set FIRMs intersection property to active
      if (arrayHasAtLeastOneItem(propertyFirms)) {
        const defaultFIRM =
          propertyFirms.find(firm => firm.isDefault) ?? propertyFirms[0];
        setActiveFirmIds([defaultFIRM.id]);
      }

      // add DFE key to FIRMs intersecting property
      const propertyFirmsWithDFEKey = propertyFirms.map(firm => {
        return {
          ...firm,
          showDFE: firm.jurisdictions.includes(FirmJurisdiction.REGULATORY),
        };
      });

      // if none of the populated firms are regulatory, we want to show the DFE
      if (
        propertyFirmsWithDFEKey.length &&
        every(propertyFirmsWithDFEKey, { showDFE: false })
      ) {
        propertyFirmsWithDFEKey[0]!.showDFE = true;
      }

      const accountFirms = data.account?.firms ?? [];

      // set NO_FIRM as active if there are no FIRMs
      if (!propertyFirms.length && !accountFirms.length) {
        setActiveFirmIds([NO_FIRM_ID]);
      }

      const emptyFIRMs = accountFirms.length
        ? accountFirms.map(firm => createEmptyFIRM(firm.id, firm.name))
        : [createEmptyFIRM(NO_FIRM_ID, "No FIRM")];

      /**
       * Merge populated FIRMs with dummy empty FIRMs.
       * After this, we are guaranteed that allFIRMs is the same length
       * as accountFirms, unless there are no account FIRMs, in which
       * case there is one dummy NO_FIRM in the list
       */
      const allFIRMs = generateAllFIRMs({
        populatedFIRMs: propertyFirmsWithDFEKey,
        emptyFIRMs,
      });

      const editableInformationForFIRMs = getEditableInformationForFIRMs(
        propertyFirmsWithDFEKey
      );

      const showCoastalA = shouldShowCoastalA({
        firms: allFIRMs.filter(firm => firm.id === activeFirmIds[0]),
        account: account!,
      });

      const firmsWithWarnings = getWarningsForFirms({
        firms: allFIRMs,
        editable: editableInformationForFIRMs,
        showCoastalA,
      }).map(firm => ({
        ...firm,
        digitized:
          data.account?.firms.find(accountFirm => accountFirm.id === firm.id)
            ?.digitized ?? false,
      }));

      setFirms(firmsWithWarnings);
      setCustomMapsInfo(data.customMapsInfo ?? []);

      const formattedDate = data.FIRMInfo.initialFIRMDate
        ? formatDateString({
            format: "MM/DD/YYYY",
            dateString: data.FIRMInfo.initialFIRMDate as string,
          })
        : undefined;

      setFirmInfo({
        queryGeometryType: data.FIRMInfo.queryGeometryType,
        preOrPostFIRM: data.FIRMInfo.preOrPostFIRM,
        initialFIRMDate: formattedDate,
      });
    },
  });

  const getPopulatedFirms = () => firms.filter(firm => !isEmptyFIRM(firm));

  return (
    <PropertyOverviewContext.Provider
      value={{
        activeFirmIds,
        setActiveFirmIds,
        firms,
        loading,
        getPopulatedFirms,
        customMapsInfo,
        latitude,
        longitude,
        ...firmInfo,
      }}
    >
      {children}
    </PropertyOverviewContext.Provider>
  );
};
