import React, { useContext } from "react";
import { AuthContext } from "../../../Authorization/AuthContext";
import { MarkdownContainer } from "../../../Common/__styles__/MarkdownContainer";
import {
  TooltipContainer,
  TooltipText,
} from "../FIRMInfo/FIRMTable/__styles__/FIRMTable";
import { InformationalTooltip } from "../../../Common/Tooltip";

export const PropertyOverviewTooltip = ({
  tooltipMarkdown,
  isGuestOnly = true,
}: {
  tooltipMarkdown?: Maybe<string>;
  isGuestOnly?: boolean;
}) => {
  const { isGuest } = useContext(AuthContext);
  if (!tooltipMarkdown || (!isGuest && isGuestOnly)) {
    return <></>;
  }

  const popoverContent = (
    <TooltipText>
      <MarkdownContainer tooltip markdown={tooltipMarkdown} />
    </TooltipText>
  );

  return (
    <TooltipContainer data-testid="tooltip">
      <InformationalTooltip popoverContent={popoverContent} place="right" />
    </TooltipContainer>
  );
};
