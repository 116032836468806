import React, { ComponentProps, ComponentType, useContext } from "react";
import { useModal } from "react-modal-hook";
import {
  FIRMWithWarnings,
  FIRMsWithWarnings,
  FIRM_COMPARISON_MODAL_WARNINGS,
} from "common-client/utils/firmInfoWarnings";

import FIRMTable from "./FIRMTable";
import Modal from "../../../Common/Modal";
import { FirmJurisdiction } from "../../../../generated/graphql";

import {
  Container,
  ContentSection,
  HeaderSection,
} from "../../../Common/__styles__/Modal";
import { TableCell, TableRow } from "../../../Common/__styles__/StripedTable";
import { PropertyOverviewContext } from "../PropertyOverviewContext";
import { FirmWarningsBanner } from "./FirmWarningsBanner";
import { WarningsWrapper } from "./__styles__/FIRMComparisonModal";
import { LayerContext } from "../../../Maps/layers";

export const useFIRMComparisonModal = (
  props: ComponentProps<typeof FIRMComparison>
) => {
  const context = useContext(PropertyOverviewContext);
  const layerContext = useContext(LayerContext);
  // We are providing a new, isolated `PropertyOverviewContext` because <Modal /> calls `Modal.setAppElement("#app")`,
  // which means it lives at the root of our html instead of a child of the caller. This means it is outside
  // the existing context. However, we can use this to our advantage by setting a different value
  // for activeFirmIds, so we can display all of them in the modal
  const activeFirmIds = context.firms.map(firm => firm.id);
  const [showModal, closeModal] = useModal(
    () => (
      <Modal onRequestClose={closeModal}>
        <LayerContext.Provider value={layerContext}>
          <PropertyOverviewContext.Provider
            value={{ ...context, activeFirmIds }}
          >
            <FIRMComparison {...props} />
          </PropertyOverviewContext.Provider>
        </LayerContext.Provider>
      </Modal>
    ),
    [props]
  );

  return [showModal, closeModal];
};

function TableFooter({
  Blank,
  firms,
}: {
  Blank: ComponentType;
  firms: FIRMsWithWarnings;
}) {
  const jurisdictionCell = ({
    firm,
    jurisdiction,
  }: {
    firm: FIRMWithWarnings;
    jurisdiction: FirmJurisdiction;
  }) => {
    const cell = firm.jurisdictions.includes(jurisdiction) ? "Yes" : <Blank />;
    return (
      <TableCell key={`${jurisdiction}-${firm.id}`} size="wide">
        {cell}
      </TableCell>
    );
  };

  return (
    <>
      <TableRow>
        <TableCell>NFIP Insurance FIRM</TableCell>
        {firms.map(firm =>
          jurisdictionCell({ firm, jurisdiction: FirmJurisdiction.NFIP })
        )}
      </TableRow>
      <TableRow>
        <TableCell>Regulatory FIRM</TableCell>
        {firms.map(firm =>
          jurisdictionCell({ firm, jurisdiction: FirmJurisdiction.REGULATORY })
        )}
      </TableRow>
    </>
  );
}

export type FIRMComparisonProps = Omit<
  ComponentProps<typeof FIRMTable>,
  "showTitle" | "Footer"
>;

function FIRMComparison({
  property,
  account,
  onFIRMEdit,
  onFIRMClick,
  section,
}: FIRMComparisonProps) {
  const { firms } = useContext(PropertyOverviewContext);

  return (
    <Container width={"wide"} data-testid="firm-comparison-modal">
      <HeaderSection>
        <h1>{section.label}</h1>
      </HeaderSection>
      <ContentSection style={{ padding: 0 }}>
        <FIRMTable
          account={account}
          property={property}
          onFIRMEdit={onFIRMEdit}
          onFIRMClick={onFIRMClick}
          showTitle={false}
          size="wide"
          isComparisonModal={true}
          Footer={props => TableFooter({ ...props, firms })}
          section={section}
        />

        <WarningsWrapper>
          <FirmWarningsBanner
            firms={firms}
            propertyId={property?.id}
            warningTextConfig={FIRM_COMPARISON_MODAL_WARNINGS}
          />
        </WarningsWrapper>
      </ContentSection>
    </Container>
  );
}

export default FIRMComparison;
