import { ReactNode, useContext } from "react";
import { FIRMTableProps, FIRMTableTooltip } from "..";
import { AuthContext } from "../../../../../Authorization/AuthContext";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { isEmptyFIRM, isXZone } from "common-client/utils/firms";
import { find } from "lodash";
import { RequestInfoLink } from "../__styles__/FIRMTable";
import { buildLink } from "common/routing";
import { track } from "../../../../../../utils/tracking";
import React from "react";
import { FIRMInfoRow } from "./FIRMInfoRow";
import { formatFeet } from "common/utils/strings";
import { RenderMode } from "../../types";
import { PropertyOverviewContext } from "../../../PropertyOverviewContext";
import { DisplayConfig } from "../../../../../../generated/graphql";
import { LayerContext } from "../../../../../Maps/layers";
import { ApproximateBfeTool } from "../../../../../Maps/ApproximateBfeTool";
import { canUseApproximateBfeTool } from "../../../../../Maps/ApproximateBfeTool/utils";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";

type BFERowProps = Pick<FIRMTableProps, "property" | "onFIRMEdit"> & {
  canUpdateFIRMInfo: boolean;
  hasRequestBFEHelpOption?: boolean;
  label: string;
  isPublic: boolean;
  tooltip?: React.ReactNode;
  size: RenderMode;
  displayConfig?: DisplayConfig;
};

export const BFERow = ({
  property,
  onFIRMEdit,
  canUpdateFIRMInfo,
  hasRequestBFEHelpOption = false,
  isPublic,
  tooltip,
  label,
  size,
  displayConfig,
}: BFERowProps) => {
  const { isGuest } = useContext(AuthContext);
  const { activeFirmIds, firms } = useContext(PropertyOverviewContext);
  const { approximateBfeToolDispatch, visibleFIRM } = useContext(LayerContext);
  const activeFirms = firms.filter(firm => activeFirmIds.includes(firm.id));
  const windowWidth = useWindowSize().width;

  const valueForFIRM = (firm: FIRMWithWarnings) => {
    const bfe = firm.stringStaticBFE;
    if (isEmptyFIRM(firm) || (!canUpdateFIRMInfo && !bfe)) {
      return null;
    }
    return bfe ?? "Add";
  };

  const wrapper = ({
    firmId,
    children,
  }: {
    children?: ReactNode;
    firmId: string;
  }) => {
    const firm = find(activeFirms, firm => firm.id === firmId)!;

    if (
      !firm.stringStaticBFE &&
      !isXZone(firm.floodzone) &&
      isGuest &&
      hasRequestBFEHelpOption
    ) {
      return (
        <RequestInfoLink
          to={buildLink(
            "getHelp",
            {},
            { propertyId: property?.id, reasonCodes: "requestBFE" }
          )}
          onClick={() => track("Request BFE")}
        >
          Request info
        </RequestInfoLink>
      );
    }

    const mapLayerFirm = visibleFIRM();

    const openApproximateBfeTool =
      canUseApproximateBfeTool({
        displayConfig,
        firm,
        windowWidth,
      }) &&
      property?.id &&
      mapLayerFirm &&
      !firm.stringStaticBFE;

    const onClick = openApproximateBfeTool
      ? () => {
          approximateBfeToolDispatch?.({
            type: "setMode",
            data: {
              mode: "on",
              render: () => {
                return (
                  <ApproximateBfeTool
                    openEditFIRM={() => onFIRMEdit({ firm, mode: "floodzone" })}
                    propertyId={property.id}
                    mapLayerFirm={mapLayerFirm}
                  />
                );
              },
            },
          });
        }
      : () => onFIRMEdit({ firm, mode: "floodzone" });

    if (property && canUpdateFIRMInfo && !isEmptyFIRM(firm)) {
      return <a onClick={onClick}>{children}</a>;
    } else {
      return <>{children}</>;
    }
  };

  const activeBFE = activeFirms[0]?.bfeSource;
  const finalTooltip = activeBFE ? (
    <FIRMTableTooltip tooltipMarkdown={activeBFE} isGuestOnly={false} />
  ) : (
    tooltip
  );

  return (
    <FIRMInfoRow
      property="stringStaticBFE"
      valueForFIRM={valueForFIRM}
      label={label}
      format={(value: string) => (value === "Add" ? value : formatFeet(value))}
      wrap={wrapper}
      skipIfBlank={!hasRequestBFEHelpOption && !isGuest}
      tooltip={finalTooltip}
      isPublic={isPublic}
      size={size}
    />
  );
};
