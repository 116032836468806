import React, {
  ReactNode,
  Ref,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Switch } from "../Inputs";
import { Group, GroupId, LayerContext } from "./layers";
import { track } from "../../utils/tracking";

import {
  LayerDot,
  LayerIcon,
  LayerName,
  LayerText,
  LayerWrapper,
  Legend,
  LegendInner,
  Row,
} from "./__styles__/Layer";
import { LayerSectionContext } from "./LayerSection";
import { useHotkeys } from "react-hotkeys-hook";
import { LayerGroupContext } from "./LayerGroup";

interface LayerProps<G extends Group> {
  name: string;
  ariaLabel?: string;
  group: G;
  id: GroupId<G>;
  disabled?: boolean;
  color?: string;
  Icon?: React.FC;
  children?: ReactNode;
}

const Layer = <G extends Group>({
  name,
  ariaLabel,
  group,
  id,
  disabled = false,
  color,
  Icon,
  children: legend,
}: LayerProps<G>) => {
  const { toggleLayer, isLayerVisible } = useContext(LayerContext);
  const { show: layerSectionExpanded } = useContext(LayerSectionContext) ?? {};
  const { show: layerGroupExpanded } = useContext(LayerGroupContext) ?? {};
  const layerTextRef: Ref<HTMLSpanElement> = useRef(null);
  const [layerTextOverflows, setLayerTextOverflows] = useState(false);

  const [isExpanded, toggleIsExpanded] = useState(false);

  const handleToggle =
    ({
      id,
      group,
    }: {
      id: LayerProps<G>["id"];
      group: LayerProps<G>["group"];
    }) =>
    (isVisible: boolean) => {
      track("Map Layers Changed", {
        layerId: id,
        layerName: name,
        layerGroup: group,
        visible: isVisible,
      });

      if (id) {
        toggleLayer({ id, group, isVisible });
      }
    };

  const hotkeyRef = useHotkeys<HTMLSpanElement>(
    ["space", "return"],
    () => {
      toggleIsExpanded(!isExpanded);
    },
    { preventDefault: true }
  );

  useLayoutEffect(() => {
    if (layerTextRef.current) {
      setLayerTextOverflows(
        layerTextRef.current.offsetWidth < layerTextRef.current.scrollWidth
      );
    }
  }, []);

  const layerTextExpandable = !!legend || layerTextOverflows;
  const layerTextExpanded = (!!legend || layerTextOverflows) && isExpanded;
  const hidden = layerSectionExpanded === false || layerGroupExpanded === false;

  return (
    <LayerWrapper>
      <Row>
        <LayerName
          id={id}
          expandable={layerTextExpandable}
          expanded={layerTextExpanded}
          aria-expanded={layerTextExpanded}
          aria-controls={`${id}-layer-text`}
          tabIndex={!layerTextExpandable || hidden ? -1 : 0}
          onClick={() => {
            toggleIsExpanded(!isExpanded);
          }}
          ref={hotkeyRef}
        >
          {!!Icon && (
            <LayerIcon>
              <Icon />
            </LayerIcon>
          )}
          {!!color && (
            <LayerDot
              css={{
                "&:before": {
                  backgroundColor: color,
                  borderColor: color === "white" ? "grey" : "white",
                },
              }}
            />
          )}
          <LayerText
            id={`${id}-layer-text`}
            ref={layerTextRef}
            expanded={layerTextOverflows && isExpanded}
            aria-label={ariaLabel ?? name}
            aria-labelledby={id}
            aria-hidden={layerTextExpandable ? hidden : undefined}
          >
            {name}
          </LayerText>
        </LayerName>
        <span>
          <Switch
            name={`${group}-${id}`}
            onChange={handleToggle({ id, group })}
            value={id ? isLayerVisible({ id, group }) : false}
            size="tiny"
            disabled={disabled}
            tabIndex={hidden ? -1 : 0}
            aria-label={ariaLabel}
          />
        </span>
      </Row>
      {!!legend && (
        <Legend expanded={isExpanded}>
          <LegendInner data-testid={`legend-${name}`}>{legend}</LegendInner>
        </Legend>
      )}
    </LayerWrapper>
  );
};

export default Layer;
