import React from "react";

import { FlexRow } from "../../__styles__/Layout";
import { Icon, IconNames } from "../../Icons/LucideIcons";
import { Attribute } from "../types";

import {
  AttributeLabel,
  RightIconWrapper,
  SectionTitleLabel,
} from "./__styles__/TableSettings";

export const SectionTitleNavigation = ({ label }: { label: string }) => {
  return (
    <SectionTitleLabel style={{ cursor: "pointer" }}>
      <Icon iconName={"chevron-left"} color="contentSecondary" size={16} />
      {label}
    </SectionTitleLabel>
  );
};

export const AttributeRow = ({
  attribute,
  rightIcon,
  disabled,
  testId,
}: {
  attribute: Pick<Attribute, "icon" | "label" | "columnId">;
  rightIcon?: {
    icon: IconNames;
    onClick?: () => void;
  };
  disabled?: boolean;
  testId?: string;
}) => {
  const { icon, label } = attribute;

  return (
    <>
      <FlexRow style={{ gap: "4px" }}>
        {icon && <Icon iconName={icon} color="contentSecondary" size={16} />}
        {label && (
          <AttributeLabel
            disabled={disabled}
            data-testid={`${attribute.columnId}-filter-select`}
          >
            {label}
          </AttributeLabel>
        )}
      </FlexRow>
      {rightIcon && (
        <RightIconWrapper
          onClick={event => {
            if (rightIcon.onClick) {
              event.stopPropagation();
              rightIcon.onClick();
            }
          }}
          hasOnClick={!!rightIcon.onClick}
        >
          <Icon
            iconName={rightIcon.icon}
            color="contentSecondary"
            size={16}
            style={{
              cursor: rightIcon.onClick ? "pointer" : "unset",
            }}
            data-testid={`${testId}-right-icon`}
            tabIndex={0}
          />
        </RightIconWrapper>
      )}
    </>
  );
};
